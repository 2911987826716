<template>
    <div class="top-main">
        <div class="top-m-body">
            <div class="top-mb-left">
                <img class="middle" src="@/assets/images/colorChange/index/i12.png" alt="" @click="topLeftChange">
                <img class="middle" src="@/assets/images/colorChange/index/logo.png" alt="">
            </div>
            <div class="top-mb-right divFlex">
                <span></span>
                <div class="block1" v-if="loginStatus">
                    <div class="middle pc-font16" @click="tabberJump(101)">{{ $t('index.i11') }}</div>
                    <div class="middle pc-font16" @click="tabberJump(102)">{{ $t('index.i10') }}</div>
                </div>
                <div class="block2" v-else>
                    <a :href="configInfo.customerService" target="_blank"><img class="middle" src="@/assets/images/colorChange/index/i13.png" style="height: 20px;"></a> 
                    <img class="middle" src="@/assets/images/colorChange/index/t1.png" style="height: 20px;" @click="tabberJump(9)"/>
                    <img class="middle" src="@/assets/images/colorChange/index/t2.png" style="width: 20px;"/>
                    <div class="money middle">
                        <div class="divFlex">
                            <div>
                                <i class="middle pc-font16">BRL</i>
                                <span class="middle pc-font14">{{ walletInfo?walletInfo.balance:0.00 }}</span>
                            </div>
                            <img src="@/assets/images/colorChange/index/fresh.png" @click="getAccountInfo"/>
                        </div>
                    </div>
                    <div class="user middle" ref="userInfoRefs">
                        <div class="content divFlex"  @click="userInfoShow = !userInfoShow">
                            <div class="name">
                                <img class="middle" src="@/assets/images/share/t1.png" alt="">
                                <div class="middle">
                                    <p class="pc-font12">{{ userInfo.username }}</p>
                                    <span class="pc-font12">ShareCode:{{ userInfo.shareCode }}</span>
                                </div>
                            </div>
                            <img :style="userInfoShow?'transform: rotate(180deg)':''" src="@/assets/images/index/next2.png" alt="">
                        </div>
                        <div class="userInfo" v-if="userInfoShow" @click="userInfoShow = false">
                            <div class="b1" @click.stop="userInfoEdit = true">
                                <img class="middle" src="@/assets/images/share/t1.png" alt="">
                                <div class="name middle" style="width: calc(100% - 48px)">
                                    <span class="pc-font16 lineBreak">{{ userInfo.username }}</span>
                                    <p><i class="middle pc-font16">ShareCode:{{ userInfo.shareCode }}</i><img class="middle" src="@/assets/images/colorChange/my/m1.png" alt=""  @click.stop="copyUrl"></p>
                                </div>
                            </div>
                            <div class="b2" @click.stop="userInfoEdit = true">
                                <img class="middle" src="@/assets/images/index/p1.png" alt="">
                                <span class="middle pc-font14">{{ userInfo.email?userInfo.email:userInfo.mobile }}</span>
                            </div>
                            <div class="b2" @click.stop="tabberJump(12)">
                                <img class="middle" src="@/assets/images/index/p2.png" alt="">
                                <span class="middle pc-font14">{{ $t('my.m9') }}</span>
                            </div>
                            <div class="b2" @click.stop="tabberJump(13)">
                                <img class="middle" src="@/assets/images/index/p3.png" alt="">
                                <span class="middle pc-font14">{{ $t('fundsDetail.fd1') }}</span>
                            </div>
                            <div class="b2"  @click.stop="tabberJump(7)">
                                <img class="middle" src="@/assets/images/index/p4.png" alt="">
                                <span class="middle pc-font14">{{ $t('my.m8')}}</span>
                            </div>
                            <div class="b2"  @click.stop="tabberJump(11)">
                                <img class="middle" src="@/assets/images/index/p5.png" alt="">
                                <span class="middle pc-font14">{{ $t('personalCenter.pc5') }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="message" v-if="false">
                        <ul>
                            <li class="divFlex">
                                <div class="left active">
                                    <img class="middle" src="@/assets/images/common/i7.png" alt="">
                                </div>
                                <div class="right">
                                    <div class="r-title ellipsis pc-font16">Message title message title meMessage title message title me</div>
                                    <div class="r-detal pc-font14">Message content message content message content message content</div>
                                    <div class="r-time">
                                        <img class="middle" src="@/assets/images/common/i8.png" alt="">
                                        <span class="middle pc-font14">2023-09-12 12:00:00</span>
                                    </div>
                                </div> 
                            </li>
                            <li class="divFlex">
                                <div class="left">
                                    <img class="middle" src="@/assets/images/common/i7.png" alt="">
                                </div>
                                <div class="right">
                                    <div class="r-title ellipsis pc-font16">Message title message title meMessage title message title me</div>
                                    <div class="r-detal pc-font14">Message content message content message content message content</div>
                                    <div class="r-time">
                                        <img class="middle" src="@/assets/images/common/i8.png" alt="">
                                        <span class="middle pc-font14">2023-09-12 12:00:00</span>
                                    </div>
                                </div> 
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <transition name="bounce"   enter-active-class="animate__animated animate__fadeInLeft"
  leave-active-class="animate__animated animate__fadeOutLeft">
        <div class="top-m-left" v-show="topLeftShow">
            <div :class="tabber == '1'?'list divFlex active':'list divFlex'" @click="tabberJump(1)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt1-1.png" alt="" v-if="tabber != '1'">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt1.png" alt="" v-else>
                <span class="middle pc-font16">{{ $t('tabber.tab1') }}</span>
            </div>
            <a class="list divFlex" :href="channerUrl" target="_blank">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt2-2.png" alt="">
                <span class="middle pc-font16">{{ $t('my.m7') }}</span>
            </a>
            <div :class="tabber == '2'?'list divFlex active':'list divFlex'" @click="tabberJump(2)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt10-10.png" alt="" v-if="tabber != '2'">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt10.png" alt="" v-else>
                <span class="middle pc-font16">{{ $t('my.m13') }}</span>
            </div>
            <div :class="tabber == '3'?'list divFlex active':'list divFlex'" @click="tabberJump(3)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt3-3.png" alt="" v-if="tabber != '3'">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt3.png" alt="" v-else>
                <span class="middle pc-font16">{{ $t('tabber.tab2') }}</span>
            </div>
            <div :class="tabber == '4'?'list divFlex active':'list divFlex'" @click="tabberJump(4)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt4-4.png" alt="" v-if="tabber != '4'">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt4.png" alt="" v-else>
                <span class="middle pc-font16">{{ $t('boxGridGame.bgg1') }}</span>
            </div>
            <div class="list divFlex" @click="tabberJump(8)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt5-5.png" alt="">
                <span class="middle pc-font16">{{ $t('my.m15') }}</span>
            </div>
            <!-- <div :class="tabber == '5'?'list divFlex active':'list divFlex'"  @click="tabberJump(5)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt6-6.png" alt=""  v-if="tabber != '5'">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt6.png" alt=""  v-else>
                <span class="middle pc-font16">{{ $t('my.m14') }}</span>
            </div> -->
            <div :class="tabber == '6'?'list divFlex active':'list divFlex'" @click="tabberJump(6)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt7-7.png" alt="" v-if="tabber != '6'">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt7.png" alt="" v-else>
                <span class="middle pc-font16">{{ $t('my.m10') }}</span>
            </div>
            <div :class="tabber == '7'?'list divFlex active':'list divFlex'" @click="tabberJump(7)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt8-8.png" alt=""  v-if="tabber != '7'">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt8.png" alt="" v-else>
                <span class="middle pc-font16">{{ $t('my.m8') }}</span>
            </div>
            <div :class="tabber == '12'?'list divFlex active':'list divFlex'" @click="tabberJump(12)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt4.png" alt="" v-if="tabber == '12'">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt4-4.png" alt="" v-else>
                <span class="middle pc-font16">{{$t('my.m9')}}</span>
            </div>
            <!-- <div :class="tabber == '12'?'list divFlex active':'list divFlex'" @click="tabberJump(12)">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt9.png" alt="" v-if="tabber == '12'">
                <img class="middle" src="@/assets/images/colorChange/tabber/pt9-9.png" alt="" v-else>
                <span class="middle pc-font16">下载引导</span>
            </div> -->
            
        </div>
        </transition>
    </div>
    <!-- 签到 -->
    <transition name="bounce" enter-active-class="animate__animated animate__backInDown"
  leave-active-class="animate__animated animate__backOutDown">
        <pc-sign  v-if="signInShow" @close="closeSignIn"/>
    </transition>
    <!-- 用户编辑 -->
    <user-edit v-if="userInfoEdit" @close="closeUserInfoEdit"/>
</template>

<script>
import API from '@/utils/request';
import pcSign from '@/components/Pc/signIn2.vue'
import userEdit from '@/components/Pc/userInfoEdit.vue'
import clipboard3 from 'vue-clipboard3';
const { toClipboard } = clipboard3();
import { showToast } from 'vant';
export default {
  name: 'PcTop',
  components: {pcSign,userEdit},
  data() {
      return{
          userInfoEdit: false,
          userInfoShow: false,
          tabber: 1,
          loginStatus: true,
          configInfo: {},
          channerUrl: "",
          signInShow: false,
          shareUrl: "",
          userInfo: {},
          walletInfo: {},
          topLeftShow: true
      }
  },
  watch: {
        '$store.getters.loginPCTop':{
            handler: function() {
                this.dataInit()
            }
        },
        '$store.getters.accountPcTop': {
            handler: function() {
                this.getAccountInfo()
            }
        },
        '$store.getters.topLeftShow': {
            handler: function(newValue, oldValue) {
                this.topLeftShow = newValue
            }
        }
  },
  mounted() {
    let that = this
    this.dataInit()
    this.getChannel()
    this.$nextTick(() => {
        if(that.$store.getters.configInfo) {
            setTimeout(() => {
                that.configInfo = that.$store.getters.configInfo
            },2000)
        }
    })
    document.addEventListener('click', this.userInfoHide)
  },
  methods: {
        topLeftChange() {
            this.topLeftShow = !this.topLeftShow
            this.$store.commit('setTopLeftShow', this.topLeftShow)
        },
        userInfoHide(e) {
            let _userInfoRefs = this.$refs.userInfoRefs;
            if(_userInfoRefs && !_userInfoRefs.contains(e.target)) {
                this.userInfoShow = false
            }
        },
        getAccountInfo() {
            let that = this;
            // API.getRequest("api/user/betInfo",{},function(res){
            //     that.accountInfo = res.data;
            // })
            API.getRequest("api/user/wallet",{},function(res){
                let dats = res.data;
                dats.balance = (dats.balance).toFixed(2);
                that.walletInfo = res.data;
            })
        },
        closeUserInfoEdit(){
            this.userInfoEdit = false
        },
        copyUrl() {
            let that = this;
            try {
                toClipboard(that.shareUrl);
                showToast(that.$t('tip.t11'));
            } catch (error) {
                showToast(that.$t('tip.t11'));
            }
        },
        dataInit() {
            let that = this;
            if(localStorage.getItem('UserInfo')) {
                that.loginStatus = false
                that.userInfo = JSON.parse(localStorage.getItem('UserInfo'))
                // that.shareUrl = window.location.origin + '/#/?shareCode=' + that.userInfo.shareCode;
                that.shareUrl = that.userInfo.shareCode;
                that.getAccountInfo()
                // 签到判断
                if(localStorage.getItem('AutoToken')) {
                    if(that.$store.getters.signInNumber == '0' && JSON.parse(localStorage.getItem('UserInfo')).checkIn == '0'){
                            that.signInShow = true
                    } else {
                            that.$store.commit('setSignInResult', that.$store.state.signInResult + 1);
                    }
                }
            } else {
                that.loginStatus = true
            }
        },
        closeSignIn() {
            this.signInShow = false
        },
        getChannel() {
            let that = this;
            API.getRequest('api/user/channel',{},function(res) {
                that.channerUrl = res.data
            })
        },
        tabberJump(e) {
            let _this = this;
            if( e != '1' && !localStorage.getItem('AutoToken') && e != '101' && e!= '102') {
                showToast(_this.$t('tip.t20'));
                setTimeout(() => {
                    _this.$store.commit('setLoginAllStatus', _this.$store.state.loginAllStatus + 1);
                },2000)
                return
            }
            switch (e) {
                case 1:
                    _this.$router.push('/');
                    _this.tabber = 1;
                    if(localStorage.getItem('UserInfo') && JSON.parse(localStorage.getItem('UserInfo')).checkIn == '0') {
                        _this.signInShow = true
                    } else {
                        _this.$store.commit('setSignInResult', _this.$store.state.signInResult + 1);
                    }
                    break;
                case 2:
                    _this.$router.push('/vip');
                    _this.tabber = 2;
                    break;
                case 3:
                    _this.$router.push('/share');
                    _this.tabber = 3;
                    break;
                case 4:
                    _this.$router.push('/boxGridGame');
                    _this.tabber = 4;
                    break;
                case 5:
                    _this.$router.push('/ranking');
                    _this.tabber = 5;
                    break;
                case 6:
                    _this.$router.push('/exchange');
                    _this.tabber = 6;
                    break;
                case 7:
                    _this.$router.push('/myTeam');
                    _this.tabber = 7;
                    break;
                case 8:
                    _this.signInShow = true
                    break;
                case 9:
                    _this.$router.push('/wallets');
                    break;
                case 101:
                    _this.$store.commit('setLoginAndRegisterButtonShow', 2);
                    _this.$store.commit('setLoginAllStatus', _this.$store.state.loginAllStatus + 1);
                    break;
                case 102:
                    _this.$store.commit('setLoginAndRegisterButtonShow', 1);
                    _this.$store.commit('setLoginAllStatus', _this.$store.state.loginAllStatus + 1);
                    break;
                case 11:
                    API.postRequest('api/logout',{}, ()=> {
                        showToast(_this.$t('tip.t16'))
                        localStorage.removeItem("AutoToken");
                        localStorage.removeItem('UserInfo');
                        _this.$store.commit('setLoginPCTop', 0);
                        localStorage.setItem('loginAndRegisterJudge', 2);
                        _this.loginStatus = true
                        _this.$router.push('/');
                    })
                    break;
                case 12:
                    _this.$router.push('/gameRecord');
                    _this.tabber = 12;
                    break;
                case 13:
                    _this.$router.push('/fundsDetail');
                    break;
            }
        }
  }
}
</script>
<!-- sass scoped -->
<style lang="scss" scoped>
.top-main{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    background: $bg-color1;
    width: 100vw;
    .top-m-body{
        height: 56px;
        padding: 0 24px 0 292px;
        position: relative;
        .top-mb-left{
            position: absolute;
            left: 24px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
            img{
                &:first-child {
                    width: 20px;
                    height: 20px;
                    margin-right: 24px;
                }
                &:last-child {
                    height: 40px;
                }
            }
        }
        .top-mb-right{
            max-width: 1216px;
            height: 56px;
            margin: 0 auto;
            position: relative;
            .block1{
                div{
                    width: 120px;
                    border-radius: 36px;
                    text-align: center;
                    line-height: 36px;
                    border: 1px solid $font-color1;
                    cursor: pointer;
                    &:first-child{
                        background: $font-color1;
                        margin-right: 16px;
                        color: $bg-color1;
                    }
                    &:last-child{
                        color: $font-color1;
                    }
                }
            }
            .block2{
                >img{
                    margin-left: 16px;
                    cursor: pointer;
                }
                >.money{
                    margin-left: 16px;
                    background-color: $bg-color2;
                    height: 32px;
                    line-height: 32px;
                    padding: 0 16px 0 0;
                    min-width: 200px;
                    i{
                        color: $font-color1;
                        font-weight: 600;
                        margin-right: 8px;
                        width: 52px;
                        height: 32px;
                        text-align: center;
                    }
                    span{
                        color: $font-color1;
                    }
                    img{
                        width: 16px;
                        height: 16px;
                        cursor: pointer;
                    }
                }
                >.user{
                    margin-left: 24px;
                    background-color: $bg-color2;
                    position: relative;
                    border-radius: 36px;
                    padding: 0 16px 0 8px;
                    min-width: 200px;
                    cursor: pointer;
                    .content{
                        height: 36px;
                        >.name{
                            margin-right: 6px;
                            img{
                                width: 24px;
                                height: 24px;
                                border-radius: 24px;
                                background-color: #fff;
                                margin-right: 6px;
                            }
                            div{
                                span{
                                    color: $font-color2;
                                }
                            }
                        }
                        >img{
                            width: 14px;
                        }
                    }
                    .userInfo{
                        position: absolute;
                        right: 0;
                        top: 46px;
                        display: block;
                        background: $linear-color1;
                        border-radius: 10px;
                        width: 260px;
                        padding: 20px 16px;
                        .b1{
                            padding-bottom: 8px;
                            >img{
                                width: 40px;
                                height: 40px;
                                border-radius: 50%;
                                background-color: #fff;
                                margin-right: 8px;
                            }
                            .name{
                                span{
                                    font-weight: 600;
                                    display: block;
                                }
                                i{
                                    opacity: .5;
                                    font-style: normal;
                                }
                                img{
                                    width: 16px;
                                    margin-left: 4px;
                                }
                            }
                        }
                        .b2{
                            margin-top: 8px;
                            height: 40px;
                            line-height: 40px;
                            border-radius: 10px;
                            background: $bg-color1;
                            padding: 0 24px;
                            img{
                                width: 16px;
                                height: 16px;
                                margin-right: 8px;
                            }
                            span{
                                width: calc(100% - 25px);
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
                >.message{
                    position: absolute;
                    right: 0;
                    top: 56px;
                    background-color: $bg-color2;
                    border-radius: 10px;
                    width: 650px;
                    padding: 0 16px 16px;
                    ul{
                        li{
                            margin-top: 16px;
                            background-color: $bg-color1;
                            border-radius: 10px;
                            padding: 16px 16px 22px;
                            .left{
                                width: 64px;
                                height: 64px;
                                text-align: center;
                                line-height: 64px;
                                border-radius: 50%;
                                background-color: rgba(232, 79, 138, .1);
                                margin-right: 16px;
                                position: relative;
                                &.active::before{
                                    content: "";
                                    width: 10px;
                                    height: 10px;
                                    background-color: $font-color3;
                                    border-radius: 10px;
                                    position: absolute;
                                    right: 0;
                                    top: 0;
                                }
                                img{
                                    width: 100%;
                                }
                            }
                            .right{
                                width: calc(100% - 80px);
                                .r-title{
                                    font-weight: 600;
                                }
                                .r-detal{
                                    opacity: .6;
                                    margin: 8px 0;
                                }
                                .r-time{
                                    img{
                                        width: 16px;
                                        height: 16px;
                                        margin-right: 8px;
                                    }
                                    span{
                                        opacity: .6;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .top-m-left{
        position: fixed;
        top: 56px;
        left: 0;
        width: 268px;
        height: calc(100vh - 56px);
        background-color: $bg-color2;
        z-index: 1000;
        padding: 24px 24px 0;
        .list{
            height: 46px;
            padding: 0 24px;
            margin-bottom: 8px;
            justify-content: flex-start;
            cursor: pointer;
            border-radius: 0 46px 46px 0;
            color: #fff;
            position: relative;
            &.active{
                background-color: $font-color3;
            }
            &.active::before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 4px;
                height: 100%;
                background-color: $font-color1;
            }
            img{
                width: 20px;
                margin-right: 16px;
            }
            &:hover{
                border: 1px solid $font-color3;
            }
        }
    }
}
</style>