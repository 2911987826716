
// import store from '@/store'
// import { Actions } from '@/store/enums/StoreEnums'
// import { v4 as uuidv4 } from 'uuid'
// import DeviceDetector from "device-detector-js";

const JSBridge = {

    isWebView(tag) {
        //console.log(tag + "=>" + window.hasOwnProperty(tag))
        return window.hasOwnProperty(tag);
    },

    isAndroidApp() {
        // if (cc.sys.isNative) {
        //     if (cc.sys.isMobile) {
        //         if (cc.sys.os == cc.sys.OS_ANDROID) {
        //             return true;
        //         }
        //     }
        // }
        return false;
    },

    isWindowsApp() {
        // if (cc.sys.isNative) {
        //     if (cc.sys.os == cc.sys.OS_WINDOWS) {
        //         return true;
        //     }
        // }
        return false;
    },

    isIOSApp() {
        // if (cc.sys.isNative) {
        //     if (cc.sys.isMobile) {
        //         if (cc.sys.os == cc.sys.OS_IOS) {
        //             return true;
        //         }
        //     }
        // }
        return false;
    },

    isWeb() {
        // if (cc.sys.isNative) {
        //     return false;
        // }
        return true;
    },

    isNative() {
        return false;
    },

    isMobildWeb() {
        return false;
    },

    isAndroidWeb() {

        return false;
    },

    isIosWeb() {
        return false;
    },

    isDesktopWeb() {
        return true;
    },

    getUid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    },

    getPlatform() {
        let platform = "";
        return platform;
    },

    CopyText(text, tipStr) {
        let tag = "jni";
        if (text == null || text == "") return;
        if (this.isWebView(tag)) {
            window[tag].Copy(text, tipStr);
        }
    },

    OpenUrl(url) {
        let tag = "jni";
        if (this.isWebView(tag)) {
            window[tag].OpenUrl(url);
        } else if (this.isWeb()) {
            let open = window.open('about:blank');
            if (open === null || typeof (open) === 'undefined') {
                window.location.href = url;
                return;
            }
            setTimeout(() => {
                open.location = url;
            }, 300)
        }
    },

    //{androidId: '', mac: '', imei: '', brand: '', mtype: '', version: '1.0', platform: 'android', packname: '', appVersion: ''}
    // getPhoneId() {
    //     let tag = "jni"
    //     if (this.isWebView(tag)) {
    //         try {
    //             let result = window[tag].PhoneInfo()
    //             var rs = JSON.parse(result)
    //             if(rs.adId == undefined && rs.Channel == 10){
    //                 rs.adId = this.getAdjustID()
    //                 rs.adType = 2
    //                 rs.gaid = rs.adId
    //                 rs.imei = 'imei_' + rs.adId
    //                 rs.version = rs.Version
    //                 rs.packname = "com.crownclub.br"
    //             }
    //             if(rs.androidId == undefined || rs.AndroidID != undefined){
    //                 rs.androidId = rs.AndroidID
    //             }
    //             console.log(JSON.stringify(rs))

    //             return rs
    //         } catch (e) {
    //             return undefined
    //         }
    //     } else {
    //         let appInfo = localStorage.getItem('appInfo') ? JSON.parse(localStorage.getItem('appInfo')) : undefined
    //         if(!appInfo){
    //             let device = new DeviceDetector().parse(navigator.userAgent)
    //             appInfo = {androidId: uuidv4(), mac: '', imei: 'imei_' + uuidv4(), brand: device.device.brand, mtype: device.device.model, version: device.os.version, platform: 'web', packname: 'org.web', appVersion: '1.0', gaid: '', adType: '', adId: ''}
    //             localStorage.setItem('appInfo', JSON.stringify(appInfo))
    //         }
    //         return appInfo
    //     }
    // },

    saveUserToken(token){
        try {
            if(this.isWebView('jni')) window['jni'].setToken(token)
        }catch (e){
            console.log(e)
        }
    },

    changeScene(v) {
        let tag = "jni";
        if (this.isWebView(tag)) {
            window[tag].ChangeOrientationH(v);
        }
    },

    loginGoogle() {
        if (this.isWeb()) window.loginGoogle();
    },

    loginFaceBook() {
        let tag = "fac";
        if (this.isWebView(tag)) {
            window[tag].login("public_profile,email");
        }
        // else if (this.isWeb()) {
        //     window.facebookLogin();
        // }
    },

    firebaseGetToken() {
        let tag = "fire";
        if (this.isWebView(tag)) {
            return window[tag].InitFirebaseToken();
        }
        return false
    },

    logOut() {
        let tag = "fac";
        if (this.isWebView(tag)) {
            // if (BlueData.getLoginType() == GameData.FaceBookLogin) window[tag].logOut();
        }
    },

    pay(hash, partnerId, txnId) {
    },

    loadNewVersion: function (newurl) {
        let tag = "jni";
        if (this.isWebView(tag)) {
            window[tag].updateGame(newurl, "title", "content");
        }
    },

    shareFaceBook(url) {
        let tag = "fac";
        if (this.isWebView(tag)) {
            window[tag].shareFaceBook(url);
        }
        else if (this.isWeb()) {
            // window.shareFaceBook();
        }
    },

    shareWhatsApp(url) {
        let tag = "jni";
        if (this.isWebView(tag)) {
            window[tag].ShareWhatsApp(url);
        }
        else if (this.isWeb()) {

        }
    },

    openCamera(node) {
        let tag = "img";
        this._showNode = node;
        if (this.isWebView(tag)) {
            window[tag].OpenCamera();
        }
        else if (this.isWeb()) {
            document.getElementById("fileBtn").click();
        }
    },

    openPhoto(node) {
        let tag = "img";
        this._showNode = node;
        if (this.isWebView(tag)) {
            window[tag].OpenPhoto();
        }
        else if (this.isWeb()) {
            document.getElementById("fileBtn").click();
        }
    },

    upload() {
        let self = this;
        var file = document.getElementById("fileBtn").files[0];
        // console.log(file);
        var reader = new FileReader();
        reader.onload = function () {
            var result = this.result;
            var img = new Image();
            img.src = result;
            var maxsize = 100 * 1024;
            //如果图片大小小于200kb，则直接上传
            if (result.length <= maxsize) {
                upload(result, file.type);
                return;
            }

            //           图片加载完毕之后进行压缩，然后上传
            if (img.complete) {
                callback();
            } else {
                img.onload = callback;
            }

            function callback() {
                var data = compress(img);
                upload(data, file.type);
                console.log("success>>>>>>>>");
            }
        };
        file && reader.readAsDataURL(file);

        //图片进行压缩
        function compress(img) {
            /*
            var canvas = document.createElement("canvas");
            var ctx = canvas.getContext('2d');
            
            // 瓦片canvas
            var tCanvas = document.createElement("canvas");
            var tctx = tCanvas.getContext("2d");
            var maxsize = 100 * 1024;
    
            var initSize = img.src.length;
            var width = img.width;
            var height = img.height;
    
            //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
            var ratio;
            if ((ratio = width * height / 4000000)>1) {
                ratio = Math.sqrt(ratio);
                width /= ratio;
                height /= ratio;
            }else {
                ratio = 1;
            }
    
            canvas.width = width;
            canvas.height = height;
    
    //        铺底色
            ctx.fillStyle = "#fff";
            ctx.fillRect(0, 0, canvas.width, canvas.height);
    
            //如果图片像素大于100万则使用瓦片绘制
            var count;
            if ((count = width * height / 1000000) > 1) {
                count = ~~(Math.sqrt(count)+1); //计算要分成多少块瓦片
    
    //            计算每块瓦片的宽和高
                var nw = ~~(width / count);
                var nh = ~~(height / count);
    
                tCanvas.width = nw;
                tCanvas.height = nh;
    
                for (var i = 0; i < count; i++) {
                    for (var j = 0; j < count; j++) {
                        tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh);
    
                        ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh);
                    }
                }
            } else {
                ctx.drawImage(img, 0, 0, width, height);
            }
    
            //进行最小压缩image/jpeg
            var ndata = canvas.toDataURL("image/jpeg", 0.1);
    
            console.log("压缩前：" + initSize);
            console.log("压缩后：" + ndata.length);
            console.log("压缩率：" + ~~(100 * (initSize - ndata.length) / initSize) + "%");
    
            tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0;
    
            return ndata;*/

            // 默认按比例压缩

            let canvas = document.createElement("canvas");
            let ctx = canvas.getContext("2d");
            let initSize = img.src.length;
            let width = img.width;
            let height = img.height;
            canvas.width = width;
            canvas.height = height;
            // 铺底色
            // ctx.fillStyle = "#fff";
            // ctx.fillRect(0, 0, canvas.width, canvas.height);
            // ctx.drawImage(img, 0, 0, width, height);

            //进行压缩
            let ndata = canvas.toDataURL("image/jpeg", 0.2);
            //console.log("*******压缩后的图片大小*******",ndata);

            return ndata;
        }

        //进行上传图片
        function upload(basestr, type) {
            // ImageNode.Create(self._showNode, basestr);
            // GlobalEvent.emit("ImgUploaded", { node: self._showNode, imgPath: "Fuck you!", imgData: basestr });
        };
    },

    afHitPoint(eventName, key, value) {
        let tag = "appsflyer";
        if (this.isWebView(tag)) {
            window[tag].appsFlyTraceEvent(eventName, key, value);
        }
    },

    getAppsFlyerId() {
        let tag = "appsflyer";
        if (this.isWebView(tag)) {
            return window[tag].getAppsFlyerId1();
        }
        return ""
    },

    /*原生调JS */

    // CallGoogleLoginBack(code, idToken, ClientId, id, name, error) {
    //     // BlueData.getCurrScene().tipText.string = " code ---- "+code+" idToken--- =="+idToken+"  error === "+error;
    //     if (code != '0') {
    //         store.dispatch(Actions.LOGIN_FOR_GOOGLE, { token: idToken }).then(() => {

    //             // router.push({ path: '/' })
    //         })
    //     }
    // },

    CallGoogleOutLoginBack() {

    },

    // CallFaceBookLoginBack(code, idToken, ClientId, error) {
    //     if (code === '0') {
    //         store.dispatch(Actions.LOGIN_FOR_FACEBOOK, { token: idToken }).then(() => {
    //             // router.push({ path: '/' })
    //         })
    //     }
    //     // alert("code: " + code + ",idToken:" + idToken + ",ClientId: " + ClientId + "err:" + error)

    // },


    CallFaceBookShareBack(code) {
        // if (code == 0) {
        //     if (BlueData.getIsShare()) SocketMgr.sendByPb(0x340B, { is_share: true, play_mode: BlueData.getPayMode() }); BlueData.setIsShare(false);
        //     CommonFun.setTip("Share Success!");
        // } else CommonFun.setTip("Share Fail");
    },

    CallJSTest(v) {
        // BlueData.getCurrScene().tipText.string += v+"";
    },

    CallJSUpdateGame(downLength, totalLength) {
        // GlobalEvent.emit("updateDownProgress", { p: downLength, t: totalLength });
    },

    CallPhoto(picPath) {
        let tag = "img";
        if (this.isWebView(tag)) {
            let str = window[tag].getPicBase64();
            let tembase64 = "data:image/png;base64," + str;
        }
    },

    sendAnalyticsEvent(cmd, jsonStr) {
        if (this.isWeb()) {

        }
    },

    sendAnalyticsProperty(cmd, jsonStr, userID) {
        if (this.isWeb()) {

        }
    },

    sendAdjustEvent(eventToken) {
        //console.log("====sendAdjustEvent====", eventToken)
       try {
           let tag = "jni";
           if (this.isWebView(tag)) window[tag].sendAd(eventToken, '' , '', '');
       }catch (e){
           let tag = "adjust";
           if (this.isWebView(tag)) window[tag].sendAdjustOrderEvent(eventToken, '' , '', '');
       }
    },

    sendAdjustOrderEvent(eventToken, amount, orderid, currency) {

        try {
            let tag = "jni";
            if (this.isWebView(tag)) window[tag].sendAd(eventToken, amount, orderid, currency)
        }catch (e){
            let tag = "adjust";
            if (this.isWebView(tag)) window[tag].sendAdjustOrderEvent(eventToken, amount, orderid, currency);
        }

    },

    getAdjustID() {
        let tag = "adjust";
        if (this.isWebView(tag)) {
            return window[tag].getAdjustId();
        }
        else if (this.isWeb()) {
            return "";
        }
    },

    sendFaceBookEvent(eventLog) {
        let tag = "fac";
        if (this.isWebView(tag)) {
            window[tag].sendFaceBookEvent(eventLog);
        }
        else if (this.isWeb()) {

        }
    },

}

export default JSBridge;