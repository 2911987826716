export default{
    tabber: {
        tab1: "Casino",
        tab2: "Invite",
        tab3: "Activity",
        tab4: "My"
    },
    my: {
        m0: 'Recharge Real',
        m1: 'Next level',
        m2: 'Bet points',
        m3: 'Balance',
        m4: 'Bonus',
        m5: 'Recharge',
        m6: 'Withdrawal',
        m7: 'Official Channel',
        m8: 'My team',
        m9: 'Game History',
        m10: 'Exchange code',
        m11: 'Change password',
        m12: 'Settings',
        m13: 'VIP',
        m14: 'Leaderboards',
        m15: 'Check-in',
        m16: "Brazil ",
        m17: "Download",
        m18: "User Editing",
        m19: "Nickname ",
        m20: "Name",
        m21: "Last name ",
        m22: "Birthday ",
        m23: "Save"
    },
    share: {
        s0: 'Sharing invitation',
        s1: 'For each first time deposit user you invite, you will receive a bonus of BRL ',
        s2: 'Copy your exclusive invitation link',
        s3: 'Copy',
        s4: 'Share through any social software',
        s5: 'Bonuses paid in the past 7 days',
        s6: 'Invitation',
        s7: 'Received',
        s8: 'People',
        s9: 'Team mode',
        s10: 'Shared friends',
        s11: 'Bet and Win Commission',
        s12: 'Proxy rule description',
        s13: 'Proxy',
        s14: 'Invite users to become members of your team, and you will receive a discount reward for their effective betting',
        s15: 'Total effective bets=total effective bets for all competitions',
        s16: 'Effective bet per game=your winning or losing amount in a single game',
        s17: 'Level 1: You will receive a ',
        s18: 'Level 2: You will receive a ',
        s171: 'discount bonus for valid bets by Level 1 members',
        s181: 'discount bonus for valid bets by Level 2 members',
        s19: 'Example: 1 participant places a daily bet of 1000R $, and rewards 100 subscribers with R $1R $to earn 100R $within 1 day',
        s20: 'Level 3: You will receive a ',
        s201: 'discount bonus for valid bets made by Level 3 members.',
        s21: 'Example: 1 participant places a daily bet of 1000R $and rewards 0.5R $100 subscribers with a daily profit of 50R $',
        s22: 'Promotion description',
        s23: '1. What are subordinates?',
        s24: 'On the sharing page, share your sharing link with your friends, making them your subordinates. People promoted by your friends can also become your subordinates. Promoting more users will bring you more commission',
        s25: '2. How to check how much subordinates have brought you',
        s26: 'On the sharing page, share your sharing link with your friends, making them your subordinates. People promoted by your friends can also become your subordinates. Promoting more users will bring you more commission',
        s27: 'My team',
        s28: 'Betting',
        s29: "The past ",
        s30: "Day Bonus"
    },
    boxGridGame: {
        bgg1: 'Nine Gauge Game',
        bgg2: 'Record',
        bgg3: 'Level Spinning Wheel',
        bgg4: 'Prize Descriptions',
        bgg5: 'Charge 50 and get 4 free raffle tickets',
        bgg6: 'Charge 100 and get 10 free raffle tickets',
        bgg7: 'Charge 100 and get 12 free raffle tickets',
        bgg8: 'Charge 200 and get 20 free raffle tickets',
        bgg9: 'One experience voucher for a particular game',
        bgg10: 'R$3 cash voucher',
        bgg11: 'R$5 cash voucher',
        bgg12: 'Remaining spins',
        bgg13: 'Immediately', 
        bgg14: 'Spin',
        bgg15: "No number of Draws",
        bgg16: "Congratulations ",
        bgg17: "Pick it up Now",
        bgg18: "Lucky Draw Record ",
        bgg19: "Use"
    },
    recharge: {
        r1: 'Top up',
        r2: 'R$',
        r3: '+',
        r4: 'Whether to use the first recharge ',
        r5: 'Whether to use the daily recharge ',
        r6: 'Payment Method',
        r7: 'Alipay',
        r8: 'WeChat',
        r9: 'Text description',
        r10: 'Please contact customer service for enquiry if you can"t recharge your account"',
        r11: ' bonus for the first recharge',
        r12: ' bonus for daily recharge',
        r13: 'Recharge Now',
        r14: "Coupon ",
        r15: "Coupon Type",
        r16: "Please select Coupon",
        r17: "No more coupon information ",
        r18: "Please select recharge amount",
        r19: "You can get",
        r20: "% bonus right"
    },
    exchange: {
        e1: "Redeem Code",
        e2: "Confirm Redemption",
        e3: "Please enter the redemption code",
        e4: "Redemption Record",
        e5: "Time",
        e6: "Code",
        e7: "Reward"
    },
    vip: {
        v1: "top-up",
        v2: "Coder Volume",
        v3: "Rights and Benefits",
        v4: "Withdrawal Fee",
        v5: "Number of withdrawals per day",
        v6: "Minimum withdrawal amount for a single payment",
        v7: "Maximum single withdrawal amount",
        v8: "Bet Yourself Rebate",
        v9: "Number of times to play the Big Spin",
        v10: "Betting Rebate",
        v11: "The amount of commission refund",
        v12: "Countdown to Settlement",
        v13: "Valid betting amount",
        v14: "Withdrawable Amount",
        v15: "Withdrawal",
        v16: "Explanation",
        v17: "Different VIP levels can get different betting rebates, you can collect the rebate of the betting amount in the last 24 hours before 17:00 every day",
        v18: "The higher the level, the higher the rebate.",
        v19: "Rebates",
        v20: "Levels of protection",
        v21: "Coding Volume in the last 30 days",
        v22: "Your VIP level will be frozen if your coding volume in the past 30 days does not meet the set requirements",
        v23: "VIP privileges are only valid for active users, if your VIP is frozen you need to complete the corresponding amount of coding to activate VIP privileges"
    },
    fundsDetail: {
        fd1: "Transactions",
        fd2: "All",
        fd3: "Recharge",
        fd4: "Withdrawal",
        s1: "No payment ",
        s2: "Payment successful ",
        s3: "Order completed ",
        s4: "Replenishment successful ",
        w1: "Third party failure ",
        w2: "Reject and return gold coins ",
        w3: "Pending Review ",
        w4: "Approved ",
        w5: "Tripartite processing ",
        w6: "Processing failure and return gold ",
        w7: "Order Completed",
        d1: "1, withdrawal hours: all day ",
        d2: "2, minimum withdrawal amount is 50",
        d3: "3, invite subordinate recharge commission can be withdrawn directly ",
        d4: "4, after the completion of the top-up, each bet can get the corresponding withdrawal amount ",
        d5: "5, please make sure the withdrawal information is filled in correctly ",
        d6: "6, in addition to inviting subordinate top-up bonuses can be withdrawn directly, other bonuses can only be withdrawn through betting",
        d7: "Tips"
    },
    myTeam: {
        mt1: "My Team",
        mt2: "Members",
        mt3: "Data",
        mt4: "Total Members",
        mt5: "New numbers added today",
        mt6: "New numbers added yesterday",
        mt7: "Users",
        mt8: "R-Time",
        mt9: "Rank",
        mt10: "All",
        mt11: "Today",
        mt12: "Yesterday",
        mt13: "This Week",
        mt14: "Start Date",
        mt15: "End Date",
        mt16: "Total number of team members",
        mt17: "Valid Bets",
        mt18: "Top-up",
        mt19: "Rebate",
        mt20: "First Charge",
        mt21: "Lv"
    },
    gameRecord: {
        gr1: "Match Record",
        gr2: "All",
        gr3: "Sort",
        gr4: "Game",
        gr5: "Place a bet",
        gr6: "Payment",
        gr7: "Royal Fishing",
        gr8: "Today ",
        gr9: "Yesterday ",
        gr10: "This week ",
        gr11: "positive order ",
        gr12: "Reverse order"
    },
    resetPassWord: {
        rpw1: "Change Password",
        rpw2: "Old password",
        rpw3: "New Password",
        rpw4: "Confirm new password",
        rpw5: "Submit Changes",
        rpw6: "please enter old password",
        rpw7: "The passwords entered twice are inconsistent"
    },
    personalCenter: {
        pc1: "Personal Centre",
        pc2: "Redeem Code",
        pc3: "Change Password",
        pc4: "Settings",
        pc5: "Exit"
    },
    common: {
        c1: "Message",
        c2: "OK ",
        c3: "Cancel",
        c4: "Previous page ",
        c5: "Next Page"
    },
    index: {
        i1: "Hall",
        i2: "Slots",
        i3: "Fishing",
        i4: "Vivo",
        i5: "viver",
        i6: "More",
        i7: "user",
        i8: "Mobile phone",
        i9: "Amount",
        i10: "Login",
        i11: "Register",
        i12: "Phone",
        i13: "Email",
        i14: "Your mobile phone number",
        i15: "Your password",
        i16: "OK",
        i17: "Forgot your password", 
        i18: "Or register",
        i19: "Remember Password",
        i20: "Reminder: frequent CAPTCHA sends will be listed as spam, please check your spam folder for CAPTCHAs." ,
        i21: "Your email address",
        i22: "Password (6-22 letters and digits)",
        i23: "Enter your password again",
        i24: "I Agree",
        i25: "Terms and conditions, Privacy policy",
        i26: "Your invitation code",
        i27: "Please click Agreement",
        i28: "Leaderboard ",
        i29: "User ",
        i30: "Nickname ",
        i31: "Amount",
        i32: "Hot",
        i33: "This site is subject to recaptcha",
        i34: "GooglePrivacy Policy",
        i35: "and",
        i36: "Terms Of Service",
        i37: "and apply.",
        t1: "Game supplier",
        t2: "Unlock levels",
    }, 
    tip: {
        t1: 'The request was successful',
        t2: 'Please contact administrator',
        t3: "Please enter a password",
        t4: "Please enter the account number",
        t5: "Please reconfirm password",
        t6: "The two passwords are not the same",
        t7: "Please enter the invitation code",
        t8: "Login Successful",
        t9: "Request failed, please contact administrator",
        t10: "Registration successful",
        t11: "Copy successful ",
        t12: "Copy Failed",
        t13: "Modified successfully ",
        t14: "Sign in successfully",
        t15: "Unable to sign in",
        t16: "Exit successfully",
        t17: "Exchange successful ",
        t18: "Please enter the redemption code",
        t19: "Successful withdrawal",
        t20: "Please login",
        t21: "Please enter the correct email number",
        t22: "No more data",
        t23: "Please enter the correct mobile number"
    },
    ranking: {
        r1: "Weekly Ranking ",
        r2: "The top 10 players in each week's bets will receive a bonus on the following Monday ",
        r3: "Award",
        r4: "Get a bonus ",
        r5: "My Ranking"
    },
    wallet: {
        w1: 'Account balance ',
        w2: 'Refund ',
        w3: 'Please fill in the withdrawal amount ',
        w4: 'withdrawable balance ',
        w5: 'Wallet ',
        w6: 'Account type PIX',
        w7: 'Account number PIX/CPF',
        w8: 'Name ',
        w9: 'Your phone number ',
        w10: 'Email ',
        w11: "Beneficiary's Tax identification number '",
        w12: 'Cash out now',
        w13: 'Id number',
        w14: "Current refundable commission Amount ",
        w15: "View Detailed History ",
        w16: "Balance Change Details",
        w17: "Personal Information ",
        w18: "Account Change Information"   
    },
    walletType: {
        wt1: "Recharge",
        wt2: "Withdraw",
        wt3: "Real Bet",
        wt4: "Bonus Bet",
        wt5: "Win",
        wt6: "Activity Bonus",
        wt7: "Rebate Real",
        wt8: "Fee",
        wt9: "Manual adjustment",
        wt10: "Refund",
        wt11: "Game Platform Bonus",
        wt12: "Cancel bet",
        wt13: "Coupon",
        wt15: "Manually Increase Real",
        wt16: "Manually Reduce Real",
        wt17: "Manually Rncrease Bonus",
        wt18: "Manually Reduce Bonus",
        wt19: "Null"
    },
    signIn: {
        s1: "Sign-in reward",
        s2: "Can be collected today",
        s3: "Sign in and receive",
        s4: "You can get it by signing in tomorrow",
        s5: "Description",
        s6: "After completing the sign-in on the second day, you can get the first day's betting amount",
        s7: "Bonus",
        r1: "Follow the official channel",
        r2: "Send screenshots to customer service",
        r3: "Receive a reward of 2 reals",
        r4: "Go to collect",
        r5: "Contact customer service",
        r6: "1. Click the public account jump link below and click to follow the public account",
        r7: "Follow the official account",
        r8: "2. Click the official customer service link below, send a screenshot of the official public account you have followed and your game ID, and get a reward of 2 reals",
        r9: "Official customer service",
        r10: "Warm reminder",
        r11: "Please contact platform customer service to handle withdrawals"
    }
    
}

