<template>
    <div class="ue-main">
        <div class="ue-m-body">
            <div class="title">
                <span>{{ $t('my.m18') }}</span>
                <img src="@/assets/images/index/t8.png" alt="" @click="onClose">
            </div>
            <ul>
                <li>
                    <p>{{ $t('my.m19') }}</p>
                    <div><input v-model="userInfo.nickname" type="text" :placeholder="$t('my.m19')"></div>
                </li>
                <li>
                    <p>{{ $t('my.m20') }}</p>
                    <div><input v-model="userInfo.firstName"  type="text" :placeholder="$t('my.m20')"></div>
                </li>
                <li>
                    <p>{{ $t('my.m21') }}</p>
                    <div><input v-model="userInfo.lastName"  type="text" :placeholder="$t('my.m21')"></div>
                </li>
                <li>
                    <p>{{ $t('my.m22') }}</p>
                    <div>
                        <el-date-picker
                            class="birthday"
                            v-model="userInfo.birthday"
                            type="date"
                            :placeholder="$t('my.m22')"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                            style="width: 100%; "
                        />
                    </div>
                    
                </li>
            </ul>
            <div class="btn" @click="userInfoPost()">{{ $t('my.m23') }}</div>  
        </div>
    </div>
</template>
<script>
import API from '@/utils/request'
import { showToast } from 'vant';
export default {
  name: 'UserEdit',
  components: { },
  data() {
    return {
        userInfo: {}
    }
  },
  mounted() {
      this.userInfoInit()
  },
  methods: {
      onClose() {
        this.$emit('close','')
      },
      onConfirm(e) {
            var d = new Date(e);
            var resDate = d.getFullYear() + '-' + ((d.getMonth() + 1)>9?(d.getMonth() + 1):'0'+(d.getMonth() + 1))+ '-' + (d.getDate()>9?d.getDate():'0'+d.getDate());
            console.log(resDate, 'resDate', d.getFullYear())
            this.userInfo.birthday = resDate
            // this.onClose()
      },
      userInfoInit() {
            let that = this;
            if(localStorage.getItem('UserInfo')) {
                that.userInfo = JSON.parse(localStorage.getItem('UserInfo'));
                console.log(that.userInfo, 'that.userInfo')
            }
      },
      endChange(e) {
            let that = this;
            that.userInfo.birthday = e.selectedValues[0] + '-' + e.selectedValues[1] + '-' + e.selectedValues[2];
            that.dayPicker.show = false
      },
      userInfoPost() {
            let that = this;
            let _userInfo = that.userInfo;
            API.postRequest('api/user/updateInfo',{
                "firstName": _userInfo.firstName,
                "nickname": _userInfo.nickname,
                "lastName": _userInfo.lastName,
                "birthday": _userInfo.birthday
            },function() {
                showToast(that.$t('tip.t1'))
                that.getUserInfo()
                that.onClose()
            },true)
      },
      getUserInfo() {
            API.getRequest("api/user/info",{},function(res) {
                localStorage.setItem('UserInfo',JSON.stringify(res.data));
            })
      },
  }
}
</script>
<!-- lang="sass" scoped -->
<style lang="scss" scoped>
.ue-main{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1005;
    background-color: rgba(30, 37, 55, .5);
    .ue-m-body{
        width: 474px;
        border-radius: 12px;
        background: $linear-color1;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        padding: 0 24px 24px;
        .title{
            line-height: 72px;
            border-bottom: 1px solid #fff;
            position: relative;
            text-align: center;
            span{
                font-size: 16px;
            }
            img{
                position: absolute;
                right: 0;
                top: 24px;
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }
        li{
            p{
                padding: 16px 0 8px;
                font-size: 14px;
                
            }
            div{
                border: 1px solid rgba(255,255,255,.8);
                border-radius: 8px;
                padding: 0 16px;
                height: 40px;
                line-height: 40px;
                input{
                    display: inline-block;
                    width: 100%;
                    font-size: 14px;
                    
                }
            }
        }
        .btn{
            margin-top: 24px;
            height: 48px;
            line-height: 48px;
            text-align: center;
            border-radius: 12px;
            background-color: $font-color3;
            font-size: 16px;
            cursor: pointer;
        }
    }
}
  
</style>