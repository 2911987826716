<template>
  <router-view/>
</template>

<script>
import API from '@/utils/request';
import JSBridge from "@/utils/JSBridge";
var timeCount;
export default {
    name: 'App',
    data() {
        return {

        }
    },
    watch: {
        '$store.getters.rechargeOrderNo':{
            handler: function(newVal,oldVal) {
                this.rechargeOrderMonitor(newVal)
            }
        }
    },
    mounted() {
        this.configInfoGet()
    },
    methods: {
        rechargeOrderMonitor(_id) {
            let that = this;
            let time = 30;
            timeCount = setInterval(function() {
                time --;
                if(time < 0) {
                    clearInterval(timeCount);
                } else {
                    that.getOrderInfo(_id, function(res) {
                        if(res.orderStatus == '2') {
                            if(res.initCharged == '0') {
                                kwaiq.instance( localStorage.getItem('kwaiqCode')).track('purchase', {
                                    content_id: res.orderNo,
                                    content_type: res.orderType,
                                    content_name: res.channelName,
                                    quantity: 1,
                                    price: res.amount,
                                    value: res.amount,
                                    currency: "BRL"
                                })
                                window.fbq && window.fbq('track', 'Purchase',{value:res.amount,currency:'BRL'});
                                JSBridge.sendAdjustOrderEvent(that.$JSBridgeObject.purchase,res.amount,res.orderNo,'BRL');
                            } else {
                                kwaiq.instance( localStorage.getItem('kwaiqCode')).track('Recharge', {
                                    content_id: res.orderNo,
                                    content_type: res.orderType,
                                    content_name: res.channelName,
                                    quantity: 1,
                                    price: res.amount,
                                    value: res.amount,
                                    currency: "BRL"
                                })
                                window.fbq && window.fbq('track', 'Recharge',{value:res.amount,currency:'BRL'});
                                JSBridge.sendAdjustOrderEvent(that.$JSBridgeObject.recharge,res.amount,res.orderNo,'BRL');
                            }
                            clearInterval(timeCount);
                        }
                    })
                }
            }, 5000)
            
        },
        getOrderInfo(e, callback) {
            API.getRequest('api/pay/order/list' ,{orderNo: e},function(res) {
                if(res.code == '0') {
                    typeof callback == 'function' && callback(res.data.list[0])
                }
            })
        },
        configInfoGet() {
            let that = this;
            let type;
            let userAgent = navigator.userAgent.toLowerCase();
            if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(userAgent)) {
                if(/(iPhone|iPad|iPod|iOS)/i.test(userAgent)) {
                    type = 2
                } else {
                    type = 1
                }
            } else {
                type = 3
            }
            API.postRequest('api/index/info',{
                deviceType: type  //1 安卓   2 ios   3 pc
            },function(res) {
                that.$store.commit('setConfigInfo', res.data);
                that.getChannel()
            })
        },
        getChannel() {
            let that = this;
            API.getRequest('api/user/channel',{},function(res) {
                that.channerUrl = res.data
                let datas = that.$store.state.configInfo
                datas.channerUrl = res.data
                that.$store.commit('setConfigInfo', datas);
            })
        }
    },
    unmounted() {
        if(timeCount) {
            clearInterval(timeCount);
        }
    }
}
</script>

<style lang="scss">

</style>
