<template>
    <div class="sr-main" v-if="tkShow">
        <div class="sr-m-body">
            <img class="close" src="@/assets/images/index/s2.png" alt="" @click="closeTk">
            <!-- <img class="main" src="@/assets/images/index/sr-bg.png" alt=""> -->
            <div class="sr-mb-body">
                <div class="title">{{ $t('signIn.r1') }}</div>
                <div class="desc">{{ $t('signIn.r3') }}</div>
                <div class="desc2">
                    <div class="content">{{ $t('signIn.r6') }}</div>
                    <a class="btn" :href="configInfo.channerUrl">
                        <img class="middle" src="@/assets/images/index/s6.png" alt="">
                        <span class="middle">{{ $t('signIn.r7') }}</span>
                    </a>
                </div>
                <div class="desc2">
                    <div class="content">{{ $t('signIn.r8') }}</div>
                    <a class="btn" :href="configInfo.customerService">
                        <img class="middle" src="@/assets/images/index/s6.png" alt="">
                        <span class="middle">{{ $t('signIn.r9') }}</span>
                    </a>
                </div>
                <!-- <div class="desc">{{ $t('signIn.r2') }}<br>{{ $t('signIn.r3') }}</div>
                <img src="@/assets/images/index/sr-logo.png" alt="">
                <div class="btn">
                    <a :href="configInfo.channerUrl">{{ $t('signIn.r4') }}</a>
                    <a :href="configInfo.customerService">{{ $t('signIn.r5') }}</a>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'SignResult',
  props: {
    tkShow: String
  },
  data() {
    return {
        configInfo: {}
    }
  },
  mounted() {
        this.$nextTick(() => {
            this.configInfo = this.$store.state.configInfo
        })
  },
  methods: {
        closeTk() {
            this.$emit('close', '')
        }
  }
}
</script>

<style lang="scss" scoped>
.sr-main{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1006;
    .sr-m-body{
        position: absolute;
        left: calc(50% - 180px);
        top: 12%;
        width: 460px;
        // >.main{
        //     width: 100%;
        //     display: block;
        // }
        >.close{
            position: absolute;
            right: 24px;
            top: 0;
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        .sr-mb-body{
            // position: absolute;
            // left: 50%;
            // top: 26%;
            // transform: translate(-50%, 0);
            // -webkit-transform: translate(-50%, 0);
            text-align: center;
            width: 100%;
            background-image: url(@/assets/images/index/sr-bg.png);
            background-size: 100% 100%;
            padding: 40% 50px 15%;
            .title{
                color: $font-color3;
                font-size: 18px;
                font-weight: 600;
            }
            .desc{
                color: $font-color3;
                font-size: 14px;
                margin-top: 8px;
            }
            .desc2{
                border: 1px solid rgba(35, 126, 255, .2);
                border-radius: 10px;
                padding: 12px 16px 26px 16px;
                position: relative;
                margin-top: 10px;
                &:first-of-type{
                    margin-top: 12px;
                }
                &:last-of-type{
                    margin-top: 34px;
                }
                .content{
                    font-size: 14px;
                    line-height: 22px;
                    text-align: left;
                    color: #333;
                }
                .btn{
                    position: absolute;
                    left: 50%;
                    bottom: -18px;
                    transform: translate(-50%, 0);
                    -webkit-transform: translate(-50%, 0);
                    line-height: 36px;
                    padding: 0 15px;
                    border-radius: 36px;
                    border: 2px solid #D3E1F1;
                    background: #FFFAEE;
                    box-shadow: 0px 4px 4px 0px rgba(35, 126, 255, 0.20);
                    white-space: nowrap;
                    z-index: 2;
                    cursor: pointer;
                    img{
                        width: 24px;
                        margin-right: 6px;
                    }
                    span{
                        font-size: 14px;
                        color: $font-color3;
                    }
                }
            }
            >img{
                width: 70px;
                display: block;
                margin: 12px auto;
                cursor: pointer;
            }
            >.btn{
                width: 90%;
                margin: 0 auto;
                >a{
                    display: block;
                    line-height: 40px;
                    font-size: 16px;
                    border-radius: 40px;
                    color: #fff;
                    cursor: pointer;
                    &:first-of-type{
                        border: .01rem solid #FDECBB;
                        background: linear-gradient(180deg, #FF5898 0%, #FF0752 100%);
                        margin-bottom: 8px;
                    }
                    &:last-of-type{
                        border: .01rem solid $font-color3;
                        color: $font-color3;
                    }
                }
            }
        }
    }
}
</style>