<template>
    <div :class="topLeftShow?'':'pcCom'">
        <pc-top />
        <router-view />
        <!-- 登录注册 -->
        <login-register :tkShow="LoginRegisterTk" @loginRegisterStatus="tkDisplay"/>
        <!-- 弹窗 -->
        <transition name="bounce" enter-active-class="animate__animated animate__backInDown"
  leave-active-class="animate__animated animate__backOutDown">
            <sign-result v-if="signResultShow" :tkShow="signResultShow" @close="signResultShow = false"/>
        </transition>
    </div>
</template>

<script>
import signResult from "@/components/Pc/signInResult.vue";
import PcTop from '@/components/Pc/top.vue';
import LoginRegister from "@/components/Pc/loginRegister.vue";
import {isMobile} from "@/utils/validate"
export default {
    name: 'PcCom',
    components: {
        PcTop,LoginRegister,signResult
    },
    data() {
        return {
            LoginRegisterTk: false,
            topLeftShow: true,
            signResultShow: false
        }
    },
    watch: {
        '$store.getters.loginAllStatus':{
            handler: function(newVal, oldVal) {
                if(newVal >= 1) {
                    if(isMobile()) {
                        return
                    } else {
                        this.LoginRegisterTk = true
                    }
                }
            }
        },
        '$store.getters.topLeftShow': {
            handler: function(newValue, oldValue) {
                this.topLeftShow = newValue
            }
        },
        '$store.getters.signInResult': {
            handler: function() {
                this.signResultShow = true
            }
        }
    },
    mounted() {
        // let that = this;
        // if(!localStorage.getItem('AutoToken')) {
        //     that.LoginRegisterTk = true         
        // }
    },
    methods: {
        signResultClose() {
            this.signResultShow = false
        },
        tkDisplay(e) {
            let that = this;
            if(e == '0') {
                that.LoginRegisterTk = false
            } else{
                that.LoginRegisterTk = true
            }
        }
    }
}
</script>
<style lang="scss">
    .pcCom .pc-common{
        padding: 56px 0 0 0;
    }
    
</style>