<template>
    <div class="i-contact">
        <a v-if="downloadUrl" :href="downloadUrl"  >
            <img class="middle" src="@/assets/images/colorChange/index/xz.svg" alt="">
        </a>
        <a v-if="customerService" :href="customerService" >
            <img class="middle" src="@/assets/images/colorChange/index/kf.svg" alt=""> 
        </a>
    </div>
</template>

<script>
export default {
  name: 'Service',
  data() {
      return {
            downloadUrl: '',
            customerService: ''
      }
  },
  mounted() {
        let that = this
        this.$nextTick(() => {
                setTimeout(()=> {
                    if(that.$store.getters.configInfo) {
                        console.log(that.$store.getters, '^^')
                        that.downloadUrl = that.$store.getters.configInfo.downloadUrl
                        that.customerService = that.$store.getters.configInfo.customerService
                    }
                },1000)
        })
  }
}
</script>

<style lang="scss" scoped>
.i-contact{
    position: fixed;
    right: .24rem;
    bottom: 1.54rem;
    z-index: 100;
    >a{
        display: block;
        width: .96rem;
        height: .96rem;
        text-align: center;
        line-height: .96rem;
        border-radius: 50%;
        margin-top: .32rem;
        background: $linear-color3;
        &:first-of-type{
            img{
                width: .41rem;
            }
        }
        &:last-of-type{
            img{
                width: .48rem;
            }
        }
    }
}
</style>