import { createRouter,  createWebHistory } from 'vue-router'
import PcCom from '../views/PcCom.vue'
import H5Com from '../views/H5Com.vue'
const routes = [
  {
    path: '/',
    component: PcCom,
    redirect: '/',
    children:[
      {
          path: "/",
          name: "Pc",
          component: () => import('@/views/Pc/index.vue'),
          meta: { title: "首页-PC", icon: "page"},
      },
      {
        path: "/share",
        name: "PcShare",
        component: () => import('@/views/Pc/share.vue'),
        meta: { title: "分享-PC", icon: "page"},
      },
      {
        path: "/boxGridGame",
        name: "PCboxGridGame",
        component: () => import('@/views/Pc/boxGridGame.vue'),
        meta: { title: "九宫格", icon: "page"},
      },
      {
        path: "/ranking",
        name: "PcRanking",
        component: () => import('@/views/Pc/ranking.vue'),
        meta: { title: "排行榜", icon: "page"},
      },
      {
        path: "/wallets",
        name: "PcWallets",
        component: () => import('@/views/Pc/wallets.vue'),
        meta: { title: "钱包", icon: "page"},
      },
      {
        path: "/walletsDetail",
        name: "PcWalletsDetail",
        component: () => import('@/views/Pc/walletsDetail.vue'),
        meta: { title: "充值提现", icon: "page"},
      },
      {
        path: "/myTeam",
        name: "PcMyTeam",
        component: () => import('@/views/Pc/myTeam.vue'),
        meta: { title: "我的团队", icon: "page"},
      },
      {
        path: "/exchange",
        name: "PcExchange",
        component: () => import('@/views/Pc/exchange.vue'),
        meta: { title: "兑换码", icon: "page"},
      },
      {
        path: "/game",
        name: "PcGame",
        component: () => import('@/views/Pc/game.vue'),
        meta: { title: "游戏", icon: "page"},
      },
      {
        path: "/vip",
        name: "PcVip",
        component: () => import('@/views/Pc/vip.vue'),
        meta: { title: "vip", icon: "page"},
      },
      {
        path: "/gameDetail",
        name: "PcGameDetail",
        component: () => import('@/views/Pc/gameDetail.vue'),
        meta: { title: "vip", icon: "page"},
      },
      {
        path: "/moneyUrl",
        name: "PcMoneyUrl",
        component: () => import('@/views/Pc/moneyUrl.vue'),
        meta: { title: "vip", icon: "page"},
      },
      {
        path: "/fundsDetail",
        name: "PcFundsDetail",
        component: () => import('@/views/Pc/fundsDetail.vue'),
        meta: { title: "vip", icon: "page"},
      },
      {
        path: "/gameRecord",
        name: "PcGameRecord",
        component: () => import('@/views/Pc/gameRecord.vue'),
        meta: { title: "vip", icon: "page"},
      }

    ]
  },
  {
    path: '/h5',
    name: "H5Com",
    component: H5Com,
    redirect: '/h5',
    children:[
      {
        path: "/h5/index",
        name: "H5",
        component: () => import('@/views/H5/index.vue'),
        meta: { title: "首页", icon: "page"},
      },
      {
        path: "/h5/activity",
        name: "Activity",
        component: () => import('@/views/H5/activity.vue'),
        meta: { title: "活动", icon: "page"},
      },
      {
        path: "/h5/my",
        name: "My",
        component: () => import('@/views/H5/my.vue'),
        meta: { title: "我的", icon: "page"},
      },
      {
        path: "/h5/share",
        name: "Share",
        component: () => import('@/views/H5/share.vue'),
        meta: { title: "分享邀请", icon: "page"},
      },
      {
        path: "/h5/boxGridGame",
        name: "BoxGridGame",
        component: () => import('@/views/H5/boxGridGame.vue'),
        meta: { title: "九宫格", icon: "page"},
      }
    ]
  },
  {
    path: "/h5/myTeam",
    name: "MyTeam",
    component: () => import('@/views/H5/myTeam.vue'),
    meta: { title: "我的团队", icon: "page"}
  },
  {
    path: "/h5/vip",
    name: "Vip",
    component: () => import('@/views/H5/vip.vue'),
    meta: { title: "VIP", icon: "page"}
  },
  {
    path: "/h5/bonusBet",
    name: "BonusBet",
    component: () => import('@/views/H5/bonusBet.vue'),
    meta: { title: "奖金赌注", icon: "page"}
  },
  {
    path: "/h5/exchange",
    name: "Exchange",
    component: () => import('@/views/H5/exchange.vue'),
    meta: { title: "兑换码", icon: "page"}
  },
  {
    path: "/h5/recharge",
    name: "Recharge",
    component: () => import('@/views/H5/recharge.vue'),
    meta: { title: "充值", icon: "page"}
  },
  {
    path: "/h5/game",
    name: "Game",
    component: () => import('@/views/H5/game.vue'),
    meta: { title: "游戏", icon: "page"}
  },
  {
    path: "/h5/personalCenter",
    name: "PersonalCenter",
    component: () => import('@/views/H5/personalCenter.vue'),
    meta: { title: "个人中心", icon: "page"}
  },
  {
    path: "/h5/fundsDetail",
    name: "FundsDetail",
    component: () => import('@/views/H5/fundsDetail.vue'),
    meta: { title: "资金明细", icon: "page"}
  },
  {
    path: "/h5/fundsDetail2",
    name: "FundsDetail2",
    component: () => import('@/views/H5/fundsDetail2.vue'),
    meta: { title: "资金明细2", icon: "page"}
  },
  {
    path: "/h5/gameRecord",
    name: "GameRecord",
    component: () => import('@/views/H5/gameRecord.vue'),
    meta: { title: "比赛记录", icon: "page"}
  },
  {
    path: "/h5/investment",
    name: "Investment",
    component: () => import('@/views/H5/investment.vue'),
    meta: { title: "提现", icon: "page"}
  },
  {
    path: "/h5/resetPassWord",
    name: "ResetPassWord",
    component: () => import('@/views/H5/resetPassWord.vue'),
    meta: { title: "修改密码", icon: "page"}
  },
  {
    path: "/h5/message",
    name: "Message",
    component: () => import('@/views/H5/message.vue'),
    meta: { title: "消息", icon: "page"}
  },
  {
    path: "/h5/gameDetail",
    name: "GameDetail",
    component: () => import('@/views/H5/gameDeatil.vue'),
    meta: { title: "游戏详情", icon: "page"}
  },
  {
    path: "/h5/wallets",
    name: "Wallets",
    component: () => import('@/views/H5/wallets.vue'),
    meta: { title: "钱包", icon: "page"}
  },
  {
    path: "/h5/ranking",
    name: "Ranking",
    component: () => import('@/views/H5/ranking.vue'),
    meta: { title: "排行版", icon: "page"}
  },
  {
    path: "/h5/moneyUrl",
    name: "MoneyUrl",
    component: () => import('@/views/H5/moneyUrl.vue'),
    meta: { title: "支付弹窗", icon: "page"}
  },
  {
    path: "/h5/boxGridGameRecord",
    name: "BoxGridGameRecord",
    component: () => import('@/views/H5/boxGridGameRecord.vue'),
    meta: { title: "抽奖记录", icon: "page"}
  },
  {
    path: "/h5/userInfoEdit",
    name: "UserInfoEdit",
    component: () => import('@/views/H5/userInfoEdit.vue'),
    meta: { title: "用户编辑", icon: "page"}
  }
  

  
]

const router = createRouter({
  // history: createWebHashHistory(),
  // mode: 'history',
  history: createWebHistory("/"),
  routes
})

export default router
