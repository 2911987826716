<template>
    <div class="footer-main">
        <!-- <img src="../../assets/images/tabber/bottom.png" /> -->
        <div class="fm-body divFlex">
            <div :class="tab == '1'?'active':''" @click="tabChange('1')">
                <img class="icon" src="@/assets/images/colorChange/tabber/tab1.png"  v-if="tab != '1'"/>
                <img class="icon" src="@/assets/images/colorChange/tabber/tab1-1.png" v-if="tab == '1'"/>
                <span>{{$t('tabber.tab1')}}</span>
                <img class="active" src="@/assets/images/colorChange/tabber/active.png" v-if="tab == '1'"/>
            </div>
            <div :class="tab == '2'?'active':''" @click="tabChange('2')">
                <img class="icon" src="@/assets/images/colorChange/tabber/tab2.png" v-if="tab != '2'"/>
                <img class="icon" src="@/assets/images/colorChange/tabber/tab2-2.png" v-if="tab == '2'"/>
                <span>{{$t('tabber.tab2')}}</span>
                <img class="active" src="@/assets/images/colorChange/tabber/active.png" v-if="tab == '2'"/>
            </div>
            <div @click="tabChange('5')">
                <img class="icon jz" src="@/assets/images/colorChange/tabber/tab5.png" />
            </div>
            <div :class="tab == '3'?'active':''" @click="tabChange('3')">
                <img class="icon" src="@/assets/images/colorChange/tabber/tab3.png"   v-if="tab != '3'"/>
                <img class="icon" src="@/assets/images/colorChange/tabber/tab3-3.png" v-if="tab == '3'"/>
                <span>{{$t('tabber.tab3')}}</span>
                <img class="active" src="@/assets/images/colorChange/tabber/active.png" v-if="tab == '3'"/>
            </div>
            <div :class="tab == '4'?'active':''" @click="tabChange('4')">
                <img class="icon" src="@/assets/images/colorChange/tabber/tab4.png"  v-if="tab != '4'"/>
                <img class="icon" src="@/assets/images/colorChange/tabber/tab4-4.png" v-if="tab == '4'"/>
                <span>{{$t('tabber.tab4')}}</span>
                <img class="active" src="@/assets/images/colorChange/tabber/active.png" v-if="tab == '4'"/>
            </div>
        </div>
    </div>
    <!-- 签到 -->
    <transition name="bounce"   enter-active-class="animate__animated animate__backInDown"
  leave-active-class="animate__animated animate__backOutDown">
        <sign-in v-if="signInShow" @close="closeSignIn"/>
    </transition>
</template>

<script>
import signIn from "@/components/H5/signIn2.vue";
import { showToast } from 'vant';
export default {
  name: 'Footer',
  components: {signIn},
  data() {
    return{
        tab: 1,
        signInShow: false
    }
  },
  watch: {
        '$store.getters.tabberTab':{
            handler: function(newVal, oldVal) {
                this.tab = newVal
            }
        },
        '$store.getters.signInNumber':{
            handler: function(newVal, oldVal) {
                if(this.$store.getters.signInNumber > 1) {
                    this.signInShow = true
                }
            }
        }
  },
  mounted() {
        const that = this
        this.tab = this.$store.getters.tabberTab
        if(localStorage.getItem('AutoToken')) {
            if(localStorage.getItem('UserInfo') && that.$store.getters.signInNumber == '0' && JSON.parse(localStorage.getItem('UserInfo')).checkIn == '0'){
                that.signInShow = true
            } else {
                that.$store.commit('setSignInResult', that.$store.state.signInResult + 1);
            }
        }
  },
  methods: {
    closeSignIn() {
        this.signInShow = false
    },
    tabChange(e) {
        const _this = this;
        if(!localStorage.getItem('AutoToken')) {
            showToast(_this.$t('tip.t20'));
            _this.$store.commit('setLoginAllStatus', _this.$store.state.loginAllStatus + 1);
            return
        }
        switch (e) {
            case "1":
                _this.$router.push('/h5/index');
                if(localStorage.getItem('UserInfo') && JSON.parse(localStorage.getItem('UserInfo')).checkIn == '0') {
                    _this.signInShow = true
                } else {
                    _this.$store.commit('setSignInResult', _this.$store.state.signInResult + 1);
                }
                break;
            case "2":
                _this.$router.push('/h5/share');
                break;
            case "3":
                _this.$router.push('/h5/activity');
                break;
            case "4":
                _this.$router.push('/h5/my');
                break;
            case "5":
                _this.$router.push('/h5/boxGridGame');
                break;
        }
        _this.$store.commit('setTabberTab', e);
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-main{
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    z-index: 1000;
    >img{
        width: 100%;
        height: 1.3rem;
        display: block;
    }
    .fm-body{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100vw;
        height: .98rem;
        justify-content: space-around;
        padding-top: 0.1rem;
        background-color: $bg-color1;
        div{
            position: relative;
            text-align: center;
            width: 25%;
            height: .8rem;
            img.jz.icon{
                display: block;
                width: 1.12rem;
                height: 1.12rem;
                margin: -.38rem auto 0;
            }
            img.icon{
                display: block;
                width: .4rem;
                height: .4rem;
                margin: 0 auto;
            }
            img.active{
                position: absolute;
                height: .12rem;
                width: .24rem;
                left: 50%;
                transform: translateX(-50%);
                bottom: 0;
            }
            span{
                display: inline-block;
                font-size: .2rem;
                color: $font-color2;
                padding: .02rem 0 .06rem;
            }
            &.active span{
                color: $font-color1;
            }
        }
    }
}

</style>