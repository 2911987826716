import router from '../router/index'
import store from '@/store';
// 路由守卫
router.beforeEach((to, from, next) => {
    // 全局控制  Pixel-begin
    if(JSON.stringify(to.query) != '{}' && to.query.kid) {
        localStorage.setItem('kwaiqCode', to.query.kid)
    }
    if(JSON.stringify(to.query) != '{}' && to.query.fid) {
        localStorage.setItem('facebookCode', to.query.fid)
    }
    // Pixel-end
    // 全局添加邀请码
    if((JSON.stringify(to.query) != '{}' && to.query.sc) || (JSON.stringify(from.query) != '{}' && from.query.sc) || to.fullPath.indexOf('sc') > -1 || from.fullPath.indexOf('sc') > -1) {
        let _shareCode = to.query.sc?to.query.sc:from.query.sc;
        if(!_shareCode) {
            if(to.fullPath.indexOf('sc') > -1) {
                _shareCode = to.fullPath.slice(to.fullPath.indexOf('sc=')+10,to.fullPath.length)
            } else {
                _shareCode = from.fullPath.slice(from.fullPath.indexOf('sc=')+10,from.fullPath.length)
            }
        }
        store.commit('setShareCode',_shareCode)
        localStorage.setItem('shareCode', _shareCode)
        to.query.sc = _shareCode;
        if(to.fullPath.indexOf('sc=') == -1) {
           if(to.fullPath.indexOf('?') > -1) {
                to.fullPath = to.fullPath + '&sc=' + _shareCode;
           } else {
                to.fullPath = to.fullPath + '?sc=' + _shareCode;
           }
        }
    } else {
        store.commit('setShareCode','')
    }
    if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
        // meta标签设置
        let judge = true;
        let list = document.getElementsByTagName('meta');
        for(let i in list) {
            if(list[i].name !== null) {
                if(list[i].name === 'viewport') {
                    judge = false;
                }
            }
        }
        if(judge) {
            let oMeta = document.createElement('meta');
            oMeta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0, user-scalable=0';
            oMeta.name = 'viewport';
            document.getElementsByTagName('head')[0].appendChild(oMeta);
        }
        //根目录设置字体  1rem = 1px
        const salepro = document.documentElement.clientWidth / 750;
        document.documentElement.style.fontSize = (100 * salepro) + 'px';
        if(to.path.indexOf('h5') > -1) {
            next();
        } else {
            let url = to.fullPath.indexOf('sc') > -1?'/h5/index' + to.fullPath.substring(to.fullPath.indexOf('?sc='),to.fullPath.length):'/h5/index';
            next(url)
        }
    } else{
        //根目录设置字体 1px = 1/100rem
        let whdef = 100 / 1440; // 表示1920的设计图,使用100PX的默认值
        let wW = window.innerWidth; // 当前窗口的宽度
        let rem = 0;
        if(wW <= 1024) {
            rem = 1024 * whdef;
        } else {
            rem = wW * whdef; // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
        }
        document.documentElement.style.fontSize = rem + 'px'
        if(to.path.indexOf('h5') > -1) {
            let url = to.fullPath.indexOf('sc') > -1?'/' + to.fullPath.substring(to.fullPath.indexOf('?sc='),to.fullPath.length):'/';
            next(url)
        } else {
            next();
        }
    }
})

router.afterEach((to, from) => {
    // 路由跳转，每次滚动到顶部
    window.scrollTo(0,1);
})