import API from '@/utils/request';
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 *  获取今天、昨天、明天的日期
 * dates为数字类型，0代表今日,-1代表昨日，1代表明日，返回yyyy-mm-dd格式字符串，dates不传默认代表
 * 
 */
export function getDate(dates) {
    var dd = new Date();
    var n = dates || 0;
    dd.setDate(dd.getDate() + n);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    var d = dd.getDate();
    m = m < 10 ? "0" + m: m;
    d = d < 10 ? "0" + d: d;
    var day = y + "-" + m + "-" + d;
    return day;
}

/**
 * 获取本周、上周、下周的起始日期
 * type为字符串类型，有两种选择，"s"代表开始,"e"代表结束，dates为数字类型，不传或0代表本周，-1代表上周，1代表下周
 * @param {*} type 
 * @param {*} dates 
 * @returns 
 */
export function getMonday(type, dates) {
    var now = new Date();
    var nowTime = now.getTime();
    var day = now.getDay();
    var longTime = 24 * 60 * 60 * 1000;
    var n = longTime * 7 * (dates || 0);
    var dd;
    if (type == "s") {
        dd = nowTime - (day - 1) * longTime + n;
    }
    if (type == "e") {
        dd = nowTime + (7 - day) * longTime + n;
    }
    dd = new Date(dd);
    var y = dd.getFullYear();
    var m = dd.getMonth() + 1;
    var d = dd.getDate();
    m = m < 10 ? "0" + m: m;
    d = d < 10 ? "0" + d: d;
    var dayNew = y + "-" + m + "-" + d;
    return dayNew;
}

/**
 * 邮箱验证
 * 
 * str 邮箱链接
 */
export function isEmail(str) {
    let reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(str);
}

/**
 * 电话号码验证（判断长度）
 * 
 */
export function isPhoneNumber(str) {
    if(str.length == 10 || str.length == 11) {
        return true;
    } else {
        return false;
    }
}

/**
 * 是否是在移动端
 * 
 */
export function isMobile() {
    const isMobile = /iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone/i.test(navigator.userAgent);
    return isMobile;
}
/**
 * vip等级图片
 * 
 */
export function vipBg(e) {
    let url;
    switch (e) {
        case '0':
            url = require('@/assets/images/vip/vip0.png')
            break;
        case '1':
            url = require('@/assets/images/vip/vip1.png')
            break;
        case '2':
            url = require('@/assets/images/vip/vip2.png')
            break;
        case '3':
            url = require('@/assets/images/vip/vip3.png')
            break;
        case '4':
            url = require('@/assets/images/vip/vip4.png')
            break;
        case '5':
            url = require('@/assets/images/vip/vip5.png')
            break;
        case '6':
            url = require('@/assets/images/vip/vip6.png')
                break;
        case '7':
            url = require('@/assets/images/vip/vip7.png')
            break;
        case '8':
            url = require('@/assets/images/vip/vip8.png')
            break;
        case '9':
            url = require('@/assets/images/vip/vip9.png')
            break;
        case '10':
            url = require('@/assets/images/vip/vip10.png')
            break;
        case '11':
            url = require('@/assets/images/vip/vip11.png')
            break;
        case '12':
            url = require('@/assets/images/vip/vip12.png')
            break;
        case '13':
            url = require('@/assets/images/vip/vip13.png')
            break;
        case '14':
            url = require('@/assets/images/vip/vip14.png')
            break;
        case '15':
            url = require('@/assets/images/vip/vip15.png')
            break;
    }
    return url
}
export function vipLogo(e) {
    let url;
    switch (e) {
        case '0':
            url = require('@/assets/images/vip/mt0.png')
            break;
        case '1':
            url = require('@/assets/images/vip/mt1.png')
            break;
        case '2':
            url = require('@/assets/images/vip/mt2.png')
            break;
        case '3':
            url = require('@/assets/images/vip/mt3.png')
            break;
        case '4':
            url = require('@/assets/images/vip/mt4.png')
            break;
        case '5':
            url = require('@/assets/images/vip/mt5.png')
            break;
        case '6':
            url = require('@/assets/images/vip/mt6.png')
                break;
        case '7':
            url = require('@/assets/images/vip/mt7.png')
            break;
        case '8':
            url = require('@/assets/images/vip/mt8.png')
            break;
        case '9':
            url = require('@/assets/images/vip/mt9.png')
            break;
        case '10':
            url = require('@/assets/images/vip/mt10.png')
            break;
        case '11':
            url = require('@/assets/images/vip/mt11.png')
            break;
        case '12':
            url = require('@/assets/images/vip/mt12.png')
            break;
        case '13':
            url = require('@/assets/images/vip/mt13.png')
            break;
        case '14':
            url = require('@/assets/images/vip/mt14.png')
            break;
        case '15':
            url = require('@/assets/images/vip/mt15.png')
            break;
    }
    return url
}

// 检测API时效性
export function checkApiTime (callback) {
    API.getRequest("api/user/info",{},() => {},true, callback)
}