<template>
    <div>
        <router-view />
        <Footer />
        <Service />
        <!-- 登录注册 -->
        <login-register :tkShow="LoginRegisterTk" @loginRegisterStatus="tkDisplay"/>
        <!-- 弹窗 -->
        <transition name="bounce" enter-active-class="animate__animated animate__backInDown"
  leave-active-class="animate__animated animate__backOutDown">
        <sign-result v-if="signResultShow" :tkShow="signResultShow" @close="signResultShow = false"/>
        </transition>
    </div>
</template>

<script>
import signResult from "@/components/H5/signInResult.vue";
import LoginRegister from "@/components/H5/loginRegister.vue";
import Footer from '@/components/H5/footer.vue';
import Service from '@/components/H5/service.vue';
import {isMobile} from "@/utils/validate"
export default {
    name: 'H5Com',
    components: { Footer,Service,LoginRegister,signResult },
    data() {
        return {
            LoginRegisterTk: false,
            signResultShow: false
        }
    },
    watch: {
        '$store.getters.loginAllStatus':{
            handler: function(newVal, oldVal) {
                if(newVal >= 1) {
                    
                    if(!isMobile()) {
                        return
                    } else {
                        this.LoginRegisterTk = true
                    }
                }
                
            }
        },
        '$store.getters.signInResult': {
            handler: function() {
                this.signResultShow = true
            }
        }
    },
    mounted() {
        // let that = this;
        // if(!localStorage.getItem('AutoToken')) {
        //     that.LoginRegisterTk = true         
        // }
    },
    methods: {
        signResultClose() {
            this.signResultShow = false
        },
        tkDisplay(e) {
            let that = this;
            if(e == '0') {
                that.LoginRegisterTk = false
            } else{
                that.LoginRegisterTk = true
            }
        }
    }
}
</script>
<style lang="scss">

</style>