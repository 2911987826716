import { createStore } from 'vuex'

export default createStore({
  state: {
      test: 1,
      shareCode: "",  // 分享码
      moneyUrl: "",  //  提现链接
      vipList: "",  // vip信息
      signInNumber: 0,  // 签到信息
      tabberTab: 1,   //  移动端tab切换
      loginAllStatus: 0,  // 登录判断
      loginPCTop: 0,   // PC头部信息
      accountPcTop: 0,  //  PC头部返回刷新标志
      configInfo: {},    // 配置跳转信息
      topLeftShow: true,   //  PC 首页显示
      loginAndRegisterButtonShow: 0,   // 0 不是按钮行为  1 登录按钮  2 注册按钮
      rechargeOrderNo: '',     //充值页面监控
      signInResult: 0,    //  签到结果
  },
  getters: {
    test: state => state.test,
    shareCode: state => state.shareCode,
    moneyUrl: state => state.moneyUrl,
    vipList: state => state.vipList,
    signInNumber:state => state.signInNumber,
    tabberTab: state => state.tabberTab,
    loginAllStatus: state => state.loginAllStatus,
    loginPCTop: state => state.loginPCTop,
    accountPcTop: state => state.accountPcTop,
    configInfo: state => state.configInfo,
    topLeftShow: state => state.topLeftShow,
    loginAndRegisterButtonShow: state => state.loginAndRegisterButtonShow,
    rechargeOrderNo: state => state.rechargeOrderNo,
    signInResult: state => state.signInResult,
  },
  mutations: {
    setTest(state, type) {
      state.test ++
    },
    setShareCode(state, type) {
      state.shareCode = type
    },
    setMoneyUrl(state, type) {
      state.moneyUrl = type
    },
    setVipList(state, type) {
      state.vipList = type
    },
    setSignInNumber(state, type) {
      state.signInNumber = type
    },
    setTabberTab(state, type) {
      state.tabberTab = type
    },
    setLoginAllStatus(state, type) {
      state.loginAllStatus = type
    },
    setLoginPCTop(state, type) {
      state.loginPCTop = type
    },
    setAccountPcTop(state, type) {
      state.accountPcTop = type
    },
    setConfigInfo(state, type) {
      state.configInfo = type
    },
    setTopLeftShow(state, type) {
      state.topLeftShow = type
    },
    setLoginAndRegisterButtonShow(state, type) {
      state.loginAndRegisterButtonShow = type
    },
    setRechargeOrderNo(state, type) {
      state.rechargeOrderNo = type
    },
    setSignInResult(state, type) {
      state.signInResult = type
    },
  },
  actions: {
    testAction(context) {
      setTimeout(() => {
        context.commit('setTest')
      }, 1000)
    }
  },
  modules: {
  }
})
