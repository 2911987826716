<template>
    <div class="s2-main">
        <div class="s2-m-body">
            <img class="close" src="@/assets/images/index/s2.png" alt="" @click="closeTk">
            <img class="main" src="@/assets/images/index/s1.png" alt="">
            <div class="title">{{ $t('signIn.s1') }}</div>
            <div class="body">
                <div class="b-t1">{{ $t('signIn.s2') }}</div>
                <div class="b-t2">R$:{{ signInfo.amount }}</div>
                <div :class="signInfo.status == '-1'?'b-t3 disable':'b-t3'" @click="signInSumbit">{{ $t('signIn.s3') }}</div>
                <div class="b-t4">{{ $t('signIn.s4') }}：<span>R$:{{ signInfo.expectAmount }}</span></div>
            </div>
            <div class="foot">
                <div class="f-t1">
                    <img class="middle" src="@/assets/images/index/s4.png" alt="">
                    <span class="middle">{{ $t('signIn.s5') }}</span>
                    <img class="middle" src="@/assets/images/index/s3.png" alt="">
                </div>
                <div class="f-t2">{{ $t('signIn.s6') }}2%{{ $t('signIn.s7') }}</div>
            </div>
        </div>
        
    </div>
</template>
<script>
import API from '@/utils/request';
import { showToast } from 'vant';
import signResult from "@/components/H5/signInResult.vue";

export default {
  name: 'SignIn2',
  props: {signResult},
  data() {
      return{
          signInfo: {}
      }
  },
  mounted() {
        let that = this;
        this.$nextTick(()=>{
            that.dataInit()
        })
  },
  methods: {
        closeTk() {
            this.$emit('close', "");
        },
        dataInit() {
            let that = this
            that.$store.commit('setSignInNumber', 1);  //  签到标记
            API.postRequest('api/user/promotion/checkInInfoV2',{},function(res) {
                console.log(res, 'game')
                that.signInfo = res.data
            })
        },
        signInSumbit() {
            let that = this
            API.postRequest('api/user/promotion/checkInV2',{},function(res) {
                that.$store.commit('setSignInResult', that.$store.state.signInResult + 1);
                showToast(that.$t('tip.t14'))
                that.getUserInfo()
                setTimeout(() => {
                    that.closeTk()
                },1000)
            })
        },
        getUserInfo() {
            API.getRequest("api/user/info",{},function(res) {
                localStorage.setItem('UserInfo',JSON.stringify(res.data));
            })
        }
  }
}

</script>
<style lang="scss" scoped>
.s2-main{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 1005;
    .s2-m-body{
        position: absolute;
        left: .22rem;
        top: 12%;
        width: calc(100% - .44rem);
        >.main{
            width: 100%;
            display: block;
        }
        >.close{
            position: absolute;
            right: 0.4rem;
            top: 0.6rem;
            width: .56rem;
            height: .56rem;
        }
        .title{
            position: absolute;
            left: 0;
            top: 2.1rem;
            font-weight: 600;
            font-size: .32rem;
            text-align: center;
            width: 100%;
        }
        .body{
            position: absolute;
            left: 0;
            top: 3.3rem;
            text-align: center;
            width: 100%;
            .b-t1{
                font-size: .32rem;
                color: $font-color3;
            }
            .b-t2{
                margin: .4rem 0;
                font-size: .56rem;
                font-weight: 700;
                color: $font-color3;
            }
            .b-t3{
                width: 4.6rem;
                line-height: .88rem;
                border-radius: .88rem;
                margin: 0 auto;
                background: linear-gradient(180deg, #477DE9 0%, #47B8E8 100%);
                font-size: .32rem;
                &.disable{
                    background: rgba(75, 89, 126, .6);
                }
            }
            .b-t4{
                font-size: .24rem;
                color: #000;
                margin-top: .24rem;
                span{
                    color: $font-color3;
                    font-size: .24rem;
                }
            }
        }
        .foot{
            position: absolute;
            left: 50%;
            bottom: .8rem;
            width: 78%;
            transform: translate(-50%, 0);
            -webkit-transform: translate(-50%, 0);
            .f-t1{
                text-align: center;
                margin-bottom: .08rem;
                img{
                    width: .56rem;
                    height: .12rem;
                }
                span{
                    font-size: .28rem;
                    margin: 0 .1rem;
                    opacity: .6;
                }
            }
            .f-t2{
                font-size: .28rem;
                opacity: .6;
                font-weight: bold;
            }
        }
    }
}
</style>