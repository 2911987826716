export default{
    tabber: {
        tab1: "首页",
        tab2: "分享邀请",
        tab3: "活动",
        tab4: "个人中心"
    },
    my: {
        m0: '充值雷亚尔',
        m1: '下一级',
        m2: '投注点数',
        m3: '余额',
        m4: '奖金',
        m5: '充值',
        m6: '提款',
        m7: '官方频道',
        m8: '我的团队',
        m9: '游戏历史输赢',
        m10: '兑换码',
        m11: '修改密码',
        m12: '设置',
        m13: 'VIP',
        m14: '排行榜',
        m15: '报到',
        m16: "巴西",
        m17: "下载",
        m18: "用户编辑",
        m19: "用户名",
        m20: "名字",
        m21: "姓",
        m22: "生日",
        m23: "保存"
    },
    share: {
        s0: '分享邀请',
        s1: '对于您邀请的每个首次存款用户，您将获得15R美元的奖金',
        s2: '复制你的专属邀请链接',
        s3: '复制链接',
        s4: '通过任何社交软件分享',
        s5: '过去7天发放的奖金',
        s6: '邀请奖金',
        s7: '收到来自',
        s8: '人',
        s9: '团队模式',
        s10: '共享好友',
        s11: '下注并赢得佣金',
        s12: '代理规则说明',
        s13: '代理',
        s14: '邀请用户成为您团队的成员，您将因其有效投注而获得折扣奖励',
        s15: '有效投注总额=所有比赛的有效投注总额',
        s16: '每场比赛的有效赌注=您在单场比赛中的输赢金额',
        s17: '1级：您将获得1级会员有效投注0.2%的折扣奖金',
        s18: '2级：您将获得2级会员有效投注0.1%的折扣奖金',
        s171: '',
        s181: '',
        s19: '示例：1名参与者每天下注1000R$，R$1R$的奖励100名订阅者在1天内赚取100R$。',
        s20: '3级：您将获得3级会员有效投注0.05%的折扣奖金',
        s201: '',
        s21: '示例：1名参与者每天下注1000R$，奖励0.5R$100名订阅者1天可赚取50R$。',
        s22: '促销说明',
        s23: '1.什么是下属？',
        s24: '在分享页面上，将你的分享链接分享给你的朋友，让你的朋友成为你的下属，你的朋友提拔的人也可以成为你的下级，提拔更多的用户会给你带来更多的佣金。',
        s25: '2.如何检查下属给你带来了多少',
        s26: '在分享页面上，将你的分享链接分享给你的朋友，让你的朋友成为你的下属，你的朋友提拔的人也可以成为你的下级，提拔更多的用户会给你带来更多的佣金。',
        s27: '我的团队',
        s28: '投注佣金',
        s29: "过去",
        s30: "天发放的奖金"
    },
    boxGridGame: {
        bgg1: '九宫格游戏',
        bgg2: '抽奖记录',
        bgg3: '等级转盘',
        bgg4: '奖品说明',
        bgg5: '充50送4奖券',
        bgg6: '充100送10奖券',
        bgg7: '充100送12奖券',
        bgg8: '充200送20奖券',
        bgg9: '某款游戏体验券一次',
        bgg10: '3雷亚尔现金券',
        bgg11: '5雷亚尔现金券',
        bgg12: '剩余可转动次数',
        bgg13: '立即',
        bgg14: '抽奖',
        bgg15: "没有抽奖次数",
        bgg16: "恭喜你",
        bgg17: "立即领取",
        bgg18: "抽奖记录",
        bgg19: "使用"
    },
    recharge: {
        r1: '充值',
        r2: '充',
        r3: '送',
        r4: '是否使用首充赠',
        r5: '是否使用日常充值赠',
        r6: '支付方式',
        r7: '支付宝',
        r8: '微信',
        r9: '文字说明',
        r10: '充值不到账请联系客服咨询',
        r11: '第一次充值可以获得X%的奖金',
        r12: '日常充值可以获得X%的奖金',
        r13: '立即充值' ,
        r14: "优惠券",
        r15: "优惠券类型",
        r16: "请选择优惠券",
        r17: "没有更多优惠券信息",
        r18: "请选择充值金额",
        r19: "可以获得",
        r20: "%奖金权利"
    },
    exchange: {
        e1: "兑换码",
        e2: "确认兑换",
        e3: "请输入兑换码",
        e4: "兑换记录",
        e5: "时间",
        e6: "兑换码",
        e7: "奖励"
    },
    vip:{
        v1: "充值",
        v2: "打码量",
        v3: "权益",
        v4: "提现手续费",
        v5: "每日提现次数",
        v6: "单笔最低提现金额",
        v7: "单笔最高提现金额",
        v8: "投注自己返佣",
        v9: "玩大转盘次数",
        v10: "投注返佣",
        v11: "当前可提取返佣金额",
        v12: "结算倒计时",
        v13: "有效投注金额",
        v14: "可提取金额",
        v15: "提现",
        v16: "说明",
        v17: "不同VIP等级可以获得不同的投注返佣，可以在每天17:00前领取上一个24小时内投注金额返佣",
        v18: "等级越高，返佣越高",
        v19: "返佣",
        v20: "等级保护",
        v21: "过去30天打码量",
        v22: "过去30天如果您的打码量未达到制定要求，您的VIP等级会被冻结",
        v23: "VIP特权只对活跃用户有效，如果您的VIP被冻结你需要完成相应打码量激活VIP特权"
    },
    fundsDetail: {
        fd1: "交易",
        fd2: "全部",
        fd3: "充值",
        fd4: "提现",
        s1: "未付款",
        s2: "支付成功",
        s3: "订单完成",
        s4: "补单成功",
        w1: "第三方失败",
        w2: "拒绝并退还金币",
        w3: "待审核",
        w4: "审核通过",
        w5: "三方处理中",
        w6: "处理失败并退金币",
        w7: "订单完成",
        d1: "1，取款开放时间：全天",
        d2: "2，最低取款金额为50",
        d3: "3，邀请下级充值佣金可直接提取",
        d4: "4，充值完成之后，每次投注可以获得对应的可提现金额",
        d5: "5，请确保提现信息填写正确",
        d6: "6，除了邀请下级充值奖金可以直接提取，其他奖金只能通过投注获得可提现金额",
        d7: "提示"
    },
    myTeam: {
        mt1: "我的团队",
        mt2: "人数",
        mt3: "数据",
        mt4: "团队总人数",
        mt5: "今日新添人数",
        mt6: "昨日新添人数",
        mt7: "用户",
        mt8: "注册时间",
        mt9: "等级",
        mt10: "全部",
        mt11: "今天",
        mt12: "昨天",
        mt13: "本周",
        mt14: "开始日期",
        mt15: "结束日期",
        mt16: "团队总人数",
        mt17: "有效投注",
        mt18: "充值",
        mt19: "返佣",
        mt20: "首充人数",
        mt21: "层级"
    },
    gameRecord: {
        gr1: "比赛记录",
        gr2: "全部",
        gr3: "排序",
        gr4: "游戏",
        gr5: "下注",
        gr6: "支付",
        gr7: "皇家钓鱼",
        gr8: "今天",
        gr9: "昨天",
        gr10: "本周",
        gr11: "正序",
        gr12: "倒序"
    },
    resetPassWord: {
        rpw1: "修改密码",
        rpw2: "旧密码",
        rpw3: "新密码",
        rpw4: "确认新密码",
        rpw5: "提交更改",
        rpw6: "请输入旧密码",
        rpw7: "新密码两次输入不一致",

    },
    personalCenter: {
        pc1: "个人中心",
        pc2: "兑换码",
        pc3: "修改密码",
        pc4: "设置",
        pc5: "退出"
    },
    common: {
        c1: "消息",
        c2: "确定",
        c3: "取消",
        c4: "上一页",
        c5: "下一页"
    },
    index: {
        i1: "大厅",
        i2: "老虎机",
        i3: "钓鱼",
        i4: "Vivo",
        i5: "viver",
        i6: "更多",
        i7: "用户",
        i8: "手机",
        i9: "金额",
        i10: "登录",
        i11: "注册",
        i12: "电话",
        i13: "邮件",
        i14: "您的手机号码",
        i15: "您的密码",
        i16: "确定",
        i17: "忘记密码",
        i18: "或注册",
        i19: "记住密码",
        i20: "提醒：频繁发送验证码会被列为垃圾邮件，请检查垃圾邮件箱中的验证码。",
        i21: "您的邮箱",
        i22: "密码（6-22 个字母和数字）",
        i23: "再次输入密码",
        i24: "我同意",
        i25: "条件和条款、隐私政策",
        i26: "您的邀请码",
        i27: "请点击协议",
        i28: "排行榜",
        i29: "用户",
        i30: "昵称",
        i31: "金额",
        i32: "热门",
        i33: "本网站受验证码和谷歌",
        i34: "隐私政策",
        i35: "和",
        i36: "服务条款",
        i37: "的约束。",
        t1: "游戏供应商",
        t2: "解锁等级",
    },
    tip: {
        t1: "请求成功",
        t2: "请联系管理员",
        t3: "请输入密码",
        t4: "请输入账号",
        t5: "请再次确认密码",
        t6: "两者密码不一致",
        t7: "请输入邀请码",
        t8: "登录成功",
        t9: "请求失败，请联系管理员",
        t10: "注册成功",
        t11: "复制成功",
        t12: "复制失败",
        t13: "修改成功",
        t14: "签到成功",
        t15: "无法签到",
        t16: "退出成功",
        t17: "兑换成功",
        t18: "请输入兑换码",
        t19: "提现成功",
        t20: "请登录",
        t21: "请输入正确的邮箱号码",
        t22: "没有更多数据",
        t23: "请输入正确的手机号码"
    },
    ranking: {
        r1: "周排行",
        r2: "每周投注前10名可在下周一获得奖金",
        r3: "奖励",
        r4: "获得奖金",
        r5: "我的排名"
    },
    wallet: {
        w1: '账户余额',
        w2: '退款',
        w3: '请填写提款金额',
        w4: '可提现余额',
        w5: '钱包',
        w6: '帐户类型PIX',
        w7: '账号PIX/CPF',
        w8: '名字',
        w9: '你的电话号码',
        w10: '电子邮件',
        w11: '受益人的税务识别号',
        w12: '立即提现',
        w13: '身份证号',
        w14: "当前可提取返佣金额",
        w15: "查看详细历史记录",
        w16: "余额变动明细",
        w17: "个人信息",
        w18: "账变信息"
    },
    walletType: {
        wt1: "充值",
        wt2: "提现",
        wt3: "真金投注",
        wt4: "赏金投注",
        wt5: "中奖",
        wt6: "活动赏金",
        wt7: "返佣真金",
        wt8: "手续费",
        wt9: "人工调账",
        wt10: "退款",
        wt11: "游戏平台赠送",
        wt12: "取消投注",
        wt13: "优惠券",
        wt15: "人工调增真金",
        wt16: "人工调减真金",
        wt17: "人工调增赏金",
        wt18: "人工调减赏金",
        wt19: "Null"
    },
    signIn: {
        s1: "签到奖励",
        s2: "今日可领取",
        s3: "签到领取",
        s4: "明日签到可领取",
        s5: "说明",
        s6: "第二天完成签到之后可以获得第一天投注量的",
        s7: "奖金",
        r1: "关注官方频道",
        r2: "发送截图给客服",
        r3: "领取2雷亚尔的奖励",
        r4: "前往领取",
        r5: "联系客服",
        r6: "1、点击下方公众号跳转链接，点击关注公众号",
        r7: "关注公众号",
        r8: "2、点击下方官方客服链接，将已关注官方公众号的截图和您的游戏ID发送，获取2雷亚尔奖励",
        r9: "官方客服",
        r10: "温馨提示",
        r11: "请联系平台客服处理提款"
    }
}