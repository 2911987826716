export default{
    tabber: {
        tab1: "Casino",
        tab2: "Convidar",
        tab3: "Actividade",
        tab4: "EU"
    },
    my: {
        m0: 'Recharge Real',
        m1: 'Próximo nível',
        m2: 'Pontos de aposta',
        m3: 'Saldo',
        m4: 'Bónus',
        m5: 'Recarga',
        m6: 'Retirada',
        m7: 'Canal Oficial',
        m8: 'Minha Equipe',
        m9: 'História do jogo',
        m10: 'Código de troca',
        m11: 'Alterar senha',
        m12: 'Configuração',
        m13: "VIP",
        m14: "classificação",
        m15: 'Check-in',
        m16: "brasil",
        m17: "download",
        m18: "User Editing",
        m19: "nome de usuário ",
        m20: "nome ",
        m21: "sobrenome ",
        m22: "aniversário ",
        m23: "salvar"
    },
    share: {
        s0: 'Partilhar convite',
        s1: 'Para cada primeiro usuário de depósito que você convidar, você receberá um bônus de US BRL ',
        s2: 'Copie o link exclusivo do convite',
        s3: 'Copiar',
        s4: 'Partilhar através de qualquer software social',
        s5: 'Bónus pagos nos últimos 7 dias',
        s6: 'Bónus de',
        s7: 'Recebido de',
        s8: 'Pessoas',
        s9: 'Modo de equipe',
        s10: 'Compartilhe um amigo',
        s11: 'Aposte e ganhe comissão',
        s12: 'Descrição da regra de proxy',
        s13: 'Naivel',
        s14: 'Convide os usuários a se tornarem membros de sua equipe e você será recompensado com descontos por suas apostas válidas',
        s15: 'Total de apostas válidas = soma de apostas válidas para todos os jogos',
        s16: 'Apostas válidas por jogo = a quantidade que você ganha ou perde em um único jogo',
        s17: 'Nível 1: Você receberá um bônus de desconto de ',
        s18: 'Nível 2: Você receberá um bônus de desconto de ',
        s171: ' das apostas válidas dos membros nível 1',
        s181: ' das apostas válidas dos membros nível 2',
        s19: 'Exemplo: 1 participante aposta 1000R$ por dia, recompensas de R$ 1R$.100 os assinantes ganham 100R$ por 1 dia.',
        s20: 'Nível 3: Você receberá um bônus de desconto de ',
        s201: ' das apostas válidas dos membros nível 3',
        s21: 'Exemplo: 1 participante aposta 1000R$ por dia, recompensas de R$ 0.5R$.100 os assinantes ganham 50R$ por 1 dia.',
        s22: 'Instruções de promoção',
        s23: '1. O que é um subordinado?',
        s24: 'Na página de compartilhamento, compartilhe seu link de compartilhamento com seus amigos, deixe seus amigos se tornarem seus subordinados, as pessoas que seus amigos promovem também podem se tornar seus subordinados, promovendo mais usuários lhe trarão mais comissões.',
        s25: '2. como verificar o quanto seus subordinados trouxeram até você',
        s26: 'Na página de compartilhamento, compartilhe seu link de compartilhamento com seus amigos, deixe seus amigos se tornarem seus subordinados, as pessoas que seus amigos promovem também podem se tornar seus subordinados, promovendo mais usuários lhe trarão mais comissões.',
        s27: 'Minha Equipe',
        s28: 'Comissão de',
        s29: "Ganhos pagos nos últimos",
        s30: "dias"
    },
    boxGridGame: {
        bgg1: "Jogo dos nove calibres",
        bgg2: 'Registo',
        bgg3: "Roda giratória de nível",
        bgg4: "Descrição dos prémios",
        bgg5: "Carregue 50 e ganhe 4 rifas grátis",
        bgg6: "Carregue 100 e ganhe 10 rifas grátis",
        bgg7: "Carregue 100 e ganhe 12 rifas grátis",
        bgg8: "Carregue 200 e ganhe 20 rifas grátis",
        bgg9: "Um vale de experiência para um determinado jogo",
        bgg10: "vale de R$3 em dinheiro",
        bgg11: "vale de R$5 em dinheiro",
        bgg12: 'Número restante de rodadas disponíveis',
        bgg13: "Imediatamente",
        bgg14: 'Sorteio',
        bgg15: "sem número de sorteio",
        bgg16: "parabéns",
        bgg17: "receba agora",
        bgg18: "registro de sorteio ",
        bgg19: "uso"
    },
    recharge: {
        r1: "Recarregar",
        r2: "R",
        r3: "+",
        r4: "Se deve utilizar o bónus da primeira recarga ",
        r5: "Se deve ser utilizado o direito à de bónus da recarga ",
        r6: "Método de pagamento",
        r7: "Alipay", 
        r8: "WeChat",
        r9: "Descrição do texto",
        r10: "Contacte o serviço de apoio ao cliente para obter informações se não conseguir recarregar a sua conta",
        r11: " no primeiro carregamento",
        r12: " no carregamento diário",
        r13: 'Recarregar agora',
        r14: "cupom de desconto ",
        r15: "tipos de cupom",
        r16: "por favor, selecione o cupom",
        r17: "não há mais informações sobre cupons ",
        r18: "por favor, selecione o valor de recarga",
        r19: "Pode obter um bónus de ",
        r20: "% direito"
    },
    exchange: {
        e1: "Código de resgate",
        e2: "Confirmar resgate",
        e3: "Por favor, introduza o código de resgate",
        e4: "Registo de resgate",
        e5: "Hora",
        e6: "Código",
        e7: "Prémio"
    },
    vip: {
        v1: "top-up",
        v2: "Volume do codificador",
        v3: "Direitos e vantagens",
        v4: "Taxa de levantamento",
        v5: "Número de levantamentos por dia",
        v6: "Valor mínimo de retirada única",
        v7: "Valor máximo de retirada única",
        v8: "Comissão de Retorno de Apostas",
        v9: "Número de vezes para jogar a Grande Volta",
        v10: "Desconto de apostas",
        v11: "Montante atual do desconto disponível para levantamento",
        v12: "Contagem decrescente para a liquidação",
        v13: "Montante de aposta válido",
        v14: "Montante a levantar",
        v15: "Levantamento",
        v16: "Explicação",
        v17: "Diferentes níveis VIP podem obter diferentes descontos de apostas, pode recolher o desconto do montante de apostas nas últimas 24 horas antes das 17:00 todos os dias",
        v18: "Quanto mais alto for o nível, maior é o desconto.", 
        v19: "desconto",
        v20: "proteção hierárquica",
        v21: "Volume de codificação nos últimos 30 dias",
        v22: "O seu nível VIP será congelado se o seu volume de codificação nos últimos 30 dias não cumprir os requisitos estabelecidos",
        v23: "Os privilégios VIP só são válidos para os utilizadores activos. Se o seu VIP for congelado, tem de completar o volume de codificação correspondente para ativar os privilégios VIP"
    },
    fundsDetail: {
        fd1: "Transação",
        fd2: "tudo ",
        fd3: "recarga ",
        fd4: "retirada de dinheiro",
        s1: "não pago ",
        s2: "pagamento bem sucedido ",
        s3: "pedido concluído ",
        s4: "preenchimento bem sucedido ",
        w1: "falha de terceiros ",
        w2: "recuse e devolva moedas de ouro ",
        w3: "a ser examinado ",
        w4: "aprovado pela auditoria ",
        w5: "tratamento tripartido em curso ",
        w6: "falha de processamento e moedas de volta ",
        w7: "pedido concluído",
        d1: "1. Horário de atendimento para saques: o dia todo",
        d2: "2. O valor mínimo de retirada é 50.",
        d3: "3. A comissão de convidar subordinados para recarregar pode ser retirada diretamente.",
        d4: "4. Após a conclusão da recarga, você poderá obter o valor de saque correspondente para cada aposta.",
        d5: "5. Certifique-se de que as informações de retirada sejam preenchidas corretamente",
        d6: "6. Exceto o bônus de recarga por convite de subordinados, que pode ser sacado diretamente, outros bônus só podem ser aumentados por meio de apostas para aumentar a quantidade de dinheiro que pode ser sacado.",
        d7: "dica"
    },
    myTeam: {
        mt1: "A minha equipa",
        mt2: "O número de",
        mt3: "Dados",
        mt4: "Total de efectivos da equipa",
        mt5: "Novas adições hoje",
        mt6: "Novas adições ontem",
        mt7: "Utilizadores",
        mt8: "Hora de registo",
        mt9: "Classificação",
        mt10: "Todos",
        mt11: "Hoje",
        mt12: "Ontem",
        mt13: "semana",
        mt14: "Data de início",
        mt15: "Data de fim",
        mt16: "Número total de membros da equipa",
        mt17: "Apostas válidas",
        mt18: "Recarga",
        mt19: "Desconto",
        mt20: "Número de primeiros carregadores",
        mt21: "Nível"
    },
    gameRecord: {
        gr1: "Registo de correspondência",
        gr2: "Todos",
        gr3: "Triagem",
        gr4: "Jogo",
        gr5: "Fazer uma aposta",
        gr6: "Pagamento",
        gr7: "Pesca real",
        gr8: "hoje ",
        gr9: "ontem ",
        gr10: "esta semana ",
        gr11: "ordem positiva ",
        gr12: "ordem invertida"
    },
    resetPassWord: {
        rpw1: "Alterar senha",
        rpw2: "senha antiga",
        rpw3: "Nova senha",
        rpw4: "Confirmar nova senha",
        rpw5: "Submeter alterações",
        rpw6: "digite a senha antiga ",
        rpw7: "a nova senha digitada duas vezes é inconsistente "
    },
    personalCenter: {
        pc1: "Centro Pessoal",
        pc2: "Resgatar código",
        pc3: "Alterar senha",
        pc4: "Definições",
        pc5: "Sair"
    },
    common: {
        c1: "mensagem ",
        c2: "certo ",
        c3: "cancelado",
        c4: "Página anterior",
        c5: "Página seguinte"
    },
    index: {
        i1: "Salao",
        i2: "Slots",
        i3: "Fishing",
        i4: "Vivo",
        i5: "viver",
        i6: "Mais",
        i7: "utilizador",
        i8: "Telemóvel",
        i9: "Montante",
        i10: "Entrar",
        i11: "Registar",
        i12: "Celular",
        i13: "Email",
        i14: "O seu número de telemóvel",
        i15: "A sua senha",
        i16: "OK",
        i17: "Esqueceu-se da sua senha", 
        i18: "Ou registe-se",
        i19: "Lembrar a senha",
        i20: "Lembrete: os envios frequentes de CAPTCHA serão listados como spam, verifique a sua pasta de spam para CAPTCHAs." ,
        i21: "O seu endereço de correio eletrónico",
        i22: "senha (6-22 letras e dígitos)",
        i23: "Introduza a sua senha novamente",
        i24: "Concordo",
        i25: "Termos e condições, Política de privacidade",
        i26: "O seu código de convite",
        i27: "por favor, clique no protocolo",
        i28: "classificação ",
        i29: "usuário ",
        i30: "apelido ",
        i31: "quantidade",
        i32: "Frequentes",
        i33: "Este site é protegido pelo google",
        i34: "Politica de privacidade",
        i35: "e",
        i36: "Termos de serviço",
        i37: "as restrições.",
        t1: "Provedores de jogos",
        t2: "Desbloquear nota",
    },
    tip: {
        t1: 'O pedido foi bem sucedido',
        t2: 'Por favor, contacte o administrador',
        t3: "Por favor, introduza uma senha",
        t4: "Por favor, introduza o número da conta",
        t5: "Por favor, reconfirme a senha",
        t6: "As duas palavras-passe não são iguais",
        t7: "Por favor, introduza o código de convite",
        t8: "Início de sessão bem sucedido",
        t9: "O pedido falhou, por favor contacte o administrador",
        t10: "Registo bem sucedido",
        t11: "cópia bem sucedida ",
        t12: "falha de cópia",
        t13: "modificado com sucesso ",
        t14: "Faça login com sucesso",
        t15: "Já fiz o check-in hoje.",
        t16: "Sair com sucesso",
        t17: "troca bem sucedida ",
        t18: "insira o código de troca",
        t19: "retirada bem-sucedida",
        t20: "por favor, faça login",
        t21: "Por favor, insira o número de e-mail correto",
        t22: "Não há mais dados",
        t23: "Por favor, digite o número de celular correto"
    },
    ranking: {
        r1: "linha da semana ",
        r2: "as 10 melhores apostas semanais são pagas na próxima segunda-feira ",
        r3: "prémios",
        r4: "obter bônus ",
        r5: "meu ranking"
    },
    wallet: {
        w1: 'saldo da conta',
        w2: "reembolso de dinheiro",
        w3: 'por favor, preencha o valor do saque ',
        w4: 'saldo disponível para retirada',
        w5: 'carteira ',
        w6: 'tipo de conta pix',
        w7: 'conta pix/cpf',
        w8: 'nome',
        w9: 'seu número de telefone',
        w10: 'e-mail ',
        w11: 'número de identificação fiscal do beneficiário',
        w12: "retirada imediata",
        w13: 'Número de identificação',
        w14: "valor atual da comissão de reembolso disponível para retirada ",
        w15: "ver histórico detalhado ",
        w16: "Variação do saldo",
        w17: "informações pessoais ",
        w18: "informação de mudança de conta"
    },
    walletType: {
        wt1: "Recarregar",
        wt2: "Retirar",
        wt3: "Aposta Real",
        wt4: "Aposta Bônus",
        wt5: "Ganhar",
        wt6: "Bônus de atividade",
        wt7: "Desconto Real",
        wt8: "Taxa",
        wt9: "Ajuste manual",
        wt10: "Reembolso",
        wt11: "Bônus da plataforma de jogo",
        wt12: "Cancelar aposta",
        wt13: "Cupom",
        wt15: "Aumentar Real",
        wt16: "Reduzir Real",
        wt17: "Bônus de aumento",
        wt18: "Reduzir bônus",
        wt19: "vazio"
    },
    signIn: {
        s1: "BÔNUS ACCESO",
        s2: "Disponível para coleta hoje",
        s3: "OBTER",
        s4: "Você pode obtê-lo fazendo login amanhã",
        s5: "ILUSTRAR",
        s6: "Depois de completar o login no segundo dia, você pode receber um bônus de",
        s7: "do valor da aposta do primeiro dia.",
        r1: "Siga a página oficial",
        r2: "Enviar capturas de tela para atendimento ao cliente",
        r3: "Receba 2 reais de prêmios",
        r4: "Vá coletar",
        r5: "Atendimento ao Cliente",
        r6: "1. Clique no botão abaixo e  abrir o link da página oficial da plataforma, clique  Siga e tire um print da tela",
        r7: "Siga a página oficial",
        r8: "2. Clique no botão abaixo e entra em contato com apoio ao ciliente oficial . Envie um print da tela da página oficial e seu ID da conta do jogo para o apoio ao ciliente oficial , e você pode receber 2 reais de  bônus.",
        r9: "Apoio ao cliente",
        r10: "Lembrete caloroso",
        r11: "Entre em contato com o atendimento ao cliente para processar a retirada"
    }
}