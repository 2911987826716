<template>
    
    <div class="lr-main" v-show="tkShow">
        <transition name="bounce"   enter-active-class="animate__animated animate__fadeInLeft"
  leave-active-class="animate__animated animate__fadeOutLeft"  v-show="tkShow">
            <div :class="tkShow?'lr-m-body active':'lr-m-body'">
                <div class="lr-mb-title divFlex">
                    <span :class="loginAndRegister == '1'?'active':''" @click="loginAndRegister = 1;resetInfo()">{{$t('index.i10')}}</span>
                    <span :class="loginAndRegister == '2'?'active':''" @click="loginAndRegister = 2;resetInfo()">{{$t('index.i11')}}</span>
                    <img src="@/assets/images/index/t8.png" alt="" @click="$emit('loginRegisterStatus', '0')">
                </div>
                <div class="lr-mb-body">
                    <div class="tab">
                        <div :class="telAndMail == '1'?'active':''" @click="telAndMail = 1;resetInfo()">{{$t('index.i12')}}</div>
                        <div :class="telAndMail == '2'?'active':''" @click="telAndMail = 2;resetInfo()">{{$t('index.i13')}}</div>
                    </div>
                    <div class="block1" v-if="loginAndRegister == '1'&& telAndMail == '1'">
                        <div class="sr">
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i17.png" alt="">
                                    <span class="middle">+55</span>
                                    <input style="width: calc(100% - 1.4rem)" class="middle" v-model="loginInfo.username" type="text" :placeholder="$t('index.i14')">
                                </div>
                            </div>
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i16.png" alt="">
                                    <input class="middle" v-model="loginInfo.password" type="password" :placeholder="$t('index.i15')">
                                </div>
                            </div>
                            <!-- <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i16.png" alt="">
                                    <input class="middle" type="text" placeholder="Código de verificação">
                                </div>
                                <i>Enviar</i>
                            </div> -->
                        </div>
                        <!-- <div class="detail divFlex">
                            <div @click="remberMe = !remberMe">
                                <img v-if="!remberMe" class="middle" src="@/assets/images/colorChange/my/d3.png" alt="">
                                <img v-else class="middle" src="@/assets/images/colorChange/my/d4.png" alt="">
                                <span class="middle">{{ $t('index.i19') }}</span>
                            </div>
                            <i>{{ $t('index.i17') }}？</i>
                        </div> -->
                        <div class="btn" @click="loginBtn">{{ $t('index.i16') }}</div>
                        <!-- <div class="other">
                            <div class="o-title"><span>{{ $t('index.i18') }}</span></div>
                            <div class="o-icon">
                                <img src="@/assets/images/index/t10.png" alt="">
                                <img src="@/assets/images/index/t9.png" alt="">
                            </div>
                        </div> -->
                    </div>
                    <div class="block2" v-if="loginAndRegister == '1'&& telAndMail == '2'">
                        <div class="sr">
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i14.png" alt="">
                                    <input class="middle" v-model="loginInfo.username" type="text" :placeholder="$t('index.i21')">
                                </div>
                            </div>
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i16.png" alt="">
                                    <input class="middle" v-model="loginInfo.password" type="password" :placeholder="$t('index.i15')">
                                </div>
                            </div>
                            <!-- <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i16.png" alt="">
                                    <input class="middle" type="text" placeholder="Código de verificação">
                                </div>
                                <i>Enviar</i>
                            </div> -->
                        </div>
                        <!-- <div class="detail divFlex">
                            <div @click="remberMe = !remberMe">
                                <img v-if="!remberMe" class="middle" src="@/assets/images/colorChange/my/d3.png" alt="">
                                <img v-else class="middle" src="@/assets/images/colorChange/my/d4.png" alt="">
                                <span class="middle">{{ $t('index.i19') }}</span>
                            </div>
                            <i>{{ $t('index.i17') }}？</i>
                        </div> -->
                        <div class="btn" @click="loginBtn">{{ $t('index.i16') }}</div>
                        <!-- <div class="other">
                            <div class="o-title"><span>{{ $t('index.i18') }}</span></div>
                            <div class="o-icon">
                                <img src="@/assets/images/index/t10.png" alt="">
                                <img src="@/assets/images/index/t9.png" alt="">
                            </div>
                            <div class="o-detail">
                                {{ $t('index.i20') }}
                            </div>
                        </div> -->
                    </div>
                    <div class="block3" v-if="loginAndRegister == '2'&& telAndMail == '1'">
                        <div class="sr">
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i17.png" alt="">
                                    <span class="middle">+55</span>
                                    <input  style="width: calc(100% - 1.4rem)"  class="middle" v-model="registerInfo.username" type="text" :placeholder="$t('index.i14')">
                                </div>
                            </div>
                            <!-- <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i16.png" alt="">
                                    <input class="middle" type="text" placeholder="Código de verificação">
                                </div>
                                <i>Enviar</i>
                            </div> -->
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i16.png" alt="">
                                    <input class="middle" v-model="registerInfo.shareCode" :disabled="shareCodeJudge" type="text" :placeholder="$t('index.i26')">
                                </div>
                            </div>
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i15.png" alt="">
                                    <input class="middle" v-model="registerInfo.password" type="password" :placeholder="$t('index.i22')">
                                </div>
                            </div>
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i15.png" alt="">
                                    <input class="middle" v-model="registerInfo.truePassword" type="password" :placeholder="$t('index.i23')">
                                </div>
                            </div>
                        </div>
                        <div class="btn" style="margin-top: .4rem;" @click="registerBtn">{{ $t('index.i16') }}</div>
                        <!-- <div class="detail" style="margin-top: .32rem;">
                            <div @click="agreeJudge = !agreeJudge">
                                <img v-if="!agreeJudge" class="middle" src="@/assets/images/colorChange/my/d3.png" alt="">
                                <img v-else class="middle" src="@/assets/images/colorChange/my/d4.png" alt="">
                                <div class="middle" style="width: calc(100% - .48rem);word-break: break-all;word-wrap: break-word;">
                                    <span>{{ $t('index.i24') }}</span><i>《{{ $t('index.i25') }}》</i>
                                </div>
                            </div>
                        </div> -->
                        
                    </div>
                    <div class="block4" v-if="loginAndRegister == '2'&& telAndMail == '2'">
                        <div class="sr">
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i14.png" alt="">
                                    <input v-model="registerInfo.username" class="middle" type="text" :placeholder="$t('index.i21')">
                                </div>
                            </div>
                            <!-- <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i16.png" alt="">
                                    <input class="middle" type="text" placeholder="Código de verificação">
                                </div>
                                <i>Enviar</i>
                            </div> -->
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i16.png" alt="">
                                    <input v-model="registerInfo.shareCode" :disabled="shareCodeJudge" class="middle" type="text" :placeholder="$t('index.i26')">
                                </div>
                            </div>
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i15.png" alt="">
                                    <input class="middle" v-model="registerInfo.password" type="password" :placeholder="$t('index.i22')">
                                </div>
                            </div>
                            <div class="sr-input divFlex">
                                <div>
                                    <img class="middle" src="@/assets/images/colorChange/index/i15.png" alt="">
                                    <input  v-model="registerInfo.truePassword" class="middle" type="password" :placeholder="$t('index.i23')">
                                </div>
                            </div>
                        </div>
                        <!-- <div class="detail2">{{ $t('index.i20') }}</div> -->
                        <div class="btn" style="margin-top: .4rem;" @click="registerBtn">{{ $t('index.i16') }}</div>
                        <!-- <div class="detail" style="margin-top: .32rem;">
                            <div @click="agreeJudge = !agreeJudge">
                                <img v-if="!agreeJudge" class="middle" src="@/assets/images/colorChange/my/d3.png" alt="">
                                <img v-else class="middle" src="@/assets/images/colorChange/my/d4.png" alt="">
                                <div class="middle" style="width: calc(100% - .48rem);word-break: break-all;word-wrap: break-word;">
                                    <span>{{ $t('index.i24') }}</span><i>《{{ $t('index.i25') }}》</i>
                                </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="detail3">
                        <span>{{ $t('index.i33') }}</span>
                        <a :href="policyUrl" >{{ $t('index.i34') }}</a>
                        <span>{{ $t('index.i35') }}</span>
                        <a :href="policyUrl" >{{ $t('index.i36') }}</a>
                        <span>{{ $t('index.i37') }}</span>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    
</template>

<script>
import API from '@/utils/request';
import { showToast } from 'vant';
import { isEmail,isPhoneNumber } from '@/utils/validate'
import JSBridge from "@/utils/JSBridge";
export default {
  name: 'PcLoginRegister',
  props: {
    tkShow: Boolean
  },
  data() {
    return {
        loginAndRegister: '1',  // 登录1  注册2
        telAndMail: '1',  // 电话1  邮件2
        registerInfo: {
            "truePassword": "",
            "password": "",
            "shareCode": "",
            "username": ""
        },
        loginInfo: {
            "password": "", //123@qq.com 123
            "username": ""
        },
        deviceInfo: {
            ua: ''
        },
        remberMe: false,
        agreeJudge: false,
        policyUrl: '',
        shareCodeJudge: false
    }
  },
  watch: {
    // 判断tab是登录还是注册
    '$store.getters.loginAndRegisterButtonShow': {
        handler: function(newValue, oldValue) {
            if(newValue > 0) {
                this.loginAndRegister = newValue + ''
            } else {
                if(localStorage.getItem('loginAndRegisterJudge') == 1) {
                    this.loginAndRegister = '1'
                } else {
                    this.loginAndRegister = '2'
                }
            }
        }
    }
  },
  mounted() {
    let that = this;
    this.$nextTick(() => {
        // if(that.$store.getters.shareCode) {
        //     that.registerInfo.shareCode = that.$store.getters.shareCode;
        //     that.shareCodeJudge = true
        // }
        // 判断tab是登录还是注册
        if(localStorage.getItem('loginAndRegisterJudge') == 1) {
            that.loginAndRegister = '1'
        } else {
            that.loginAndRegister = '2'
        }

        if(that.$store.getters.shareCode) {
            that.registerInfo.shareCode = that.$store.getters.shareCode;
            that.shareCodeJudge = true
        }
        setTimeout(()=> {
            if(that.$store.getters.configInfo) {
                that.policyUrl = that.$store.getters.configInfo.policyUrl
            }
        },1000)
        that.deviceInfo.ua = navigator.userAgent;
    })
    
  },
  methods: {
    resetInfo() {
        let that = this;
        that.registerInfo.truePassword = "";
        that.registerInfo.password = "";
        that.registerInfo.username = "";
        that.loginInfo.password = "";
        that.loginInfo.username = "";
        that.remberMe = false;
        that.agreeJudge = false;
    },
    registerBtn() {
        let that = this;
        const _registerInfo = that.registerInfo;
        if(!_registerInfo.username) {
            showToast(that.$t('tip.t4'))
            return;
        }
        if(!_registerInfo.password) {
            showToast(that.$t('tip.t3'))
            return;
        }
        if(!_registerInfo.truePassword) {
            showToast(that.$t('tip.t5'))
            return;
        }
        if(_registerInfo.password != _registerInfo.truePassword) {
            showToast(that.$t('tip.t6'))
            return;
        }
        // if(!that.agreeJudge) {
        //     showToast(that.$t('index.i27'))
        //     return;
        // }
        // if(!_registerInfo.shareCode) {
        //     showToast(that.$t('tip.t7'))
        //     return;
        // }
        if(that.telAndMail == '1' && !isPhoneNumber(_registerInfo.username)) {
            showToast(that.$t('tip.t23'))
            return;
        }
        if(that.telAndMail == '2' && !isEmail(_registerInfo.username)) {
            showToast(that.$t('tip.t21'))
            return;
        }
        API.postRequest('api/register', {
            "deviceInfo": JSON.stringify(that.deviceInfo),
            "password": _registerInfo.password,
            "shareCode": _registerInfo.shareCode,
            "username": _registerInfo.username
        }, function(res) {
            if(res.code == '0') {
                // that.loginAndRegister = '1';
                // that.telAndMail = '1';
                // that.resetInfo();
                kwaiq.instance( localStorage.getItem('kwaiqCode')).track('completeRegistration');
                window.fbq && window.fbq('track', 'CompleteRegistration');
                JSBridge.sendAdjustEvent(that.$JSBridgeObject.completeRegistration);
                showToast(that.$t('tip.t10'));
                setTimeout(() => {
                    localStorage.setItem("AutoToken", res.data.token);
                    localStorage.setItem('UserInfo',JSON.stringify(res.data.user));
                    that.$router.push('/');
                    that.$emit('loginRegisterStatus', "0");
                    that.$store.commit('setLoginPCTop', 1);
                    that.getVipInfo();
                }, 2000);
            }
        })
    },
    loginBtn() {
        let that = this;
        let _loginInfo = that.loginInfo;
        if(!_loginInfo.username) {
            showToast(that.$t('tip.t4'))
            return;
        }
        if(!_loginInfo.password) {
            showToast(that.$t('tip.t3'))
            return;
        }
        if(that.telAndMail == '1' && !isPhoneNumber(_loginInfo.username)) {
            showToast(that.$t('tip.t23'))
            return;
        }
        if(that.telAndMail == '2' && !isEmail(_loginInfo.username)) {
            showToast(that.$t('tip.t21'))
            return;
        }
        API.postRequest('api/login', {
            "deviceInfo": JSON.stringify(that.deviceInfo),
            "password": _loginInfo.password,
            "username": _loginInfo.username
        },function(res) {
            if(res.code == '0') {
                showToast(that.$t('tip.t8'));
                setTimeout(() => {
                    localStorage.setItem("AutoToken", res.data.token);
                    localStorage.setItem('UserInfo',JSON.stringify(res.data.user));
                    that.$router.push('/');
                    that.$emit('loginRegisterStatus', "0");
                    that.$store.commit('setLoginPCTop', 1);
                    that.getVipInfo();
                },2000)
            } else {
                showToast(that.$t('tip.t9'));
            }
        })
    },
    getVipInfo() {
        let that = this;
        API.getRequest('api/level/list', {}, (res) => {
            if(res.code == '0') {
                that.$store.commit('setVipList', JSON.stringify(res.data));
                localStorage.setItem('VipList',JSON.stringify(res.data))
            }
        })
    }

  }
}
</script>

<style lang="scss" scoped>
.lr-main{
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1005;
    background-color: rgba(30, 37, 55, .5);
    overflow: hidden;
    .lr-m-body{
        position: absolute;
        left: 50%;
        top: 150px;
        transform: translate(-50%, 0);
        background: $bg-color2;
        width: 548px;
        border-radius: 12px;
        overflow: hidden;
        padding: 0 24px 24px;
        transition: all 1s linear;
        opacity: 0;
        &.active{
            opacity: 1;
        }
        .lr-mb-title{
            justify-content: space-around;
            border-bottom: 1px solid rgba(255,255,255,.1);
            position: relative;
            span{
                display: inline-block;
                height: 68px;
                font-size: 16px;
                line-height: 68px;
                cursor: pointer;
                &.active{
                    color: $font-color3;
                    border-bottom: 4px solid $font-color3;
                    font-weight: 600;
                }
            }
            img{
                position: absolute;
                right: 0rem;
                top: 24px;
                width: 20px;
                height: 20px;
                cursor: pointer;
            }
        }
        .lr-mb-body{
            .tab{
                margin: 16px auto;
                border-radius: 40px;
                width: 200px;
                background-color: $bg-color1;
                overflow: hidden;
                >div{
                    display: inline-block;
                    width: 50%;
                    height: 40px;
                    text-align: center;
                    line-height:40px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $font-color3;
                    cursor: pointer;
                    &.active{
                        background-color: $font-color3;
                        color: #fff;
                    }
                }
            }
            .sr{
                .sr-input{
                    margin-top: 16px;
                    background-color: $bg-color1;
                    border-radius: 8px;
                    padding: 0 24px;
                    height: 40px;
                    >div{
                        width: 100%;
                        img{
                            width: 20px;
                            margin-right: 18px;
                        }
                        // span{
                        //     font-size: .32rem;
                        //     color: rgba(255,255,255,.4);
                        //     margin-right: .16rem;
                        // }
                        input{
                            font-size: 14px;
                            line-height: 22px;
                            width: calc(100% - 40px);
                        }
                    }
                    >i{
                        width: 93px;
                        height: 30px;
                        line-height: 30px;
                        text-align: center;
                        font-size: 14px;
                        background-color: rgba(232, 79, 138, .8);
                        border-radius: 4px;
                        font-style: normal;
                    }
                }
            }
            // .detail{
            //     height: 1.03rem;
            //     >div{
            //         img{
            //             width: .32rem;
            //             height: .32rem;
            //             margin-right: .16rem;
            //         }
            //         span{
            //             font-size: .28rem;
            //         }
            //     }
            //     i{
            //         font-size: .28rem;
            //         font-style: normal;
            //         color: $font-color3;
            //         text-decoration: underline;
            //         margin-left: .2rem;
            //     }
            // }
            // .detail2{
            //     margin-top: .24rem;
            //     font-size: .28rem;
            //     opacity: .8;
            // }
            .detail3{
                margin: 16px 0;
                line-height: 24px;
                text-align: center;
                span,a{
                    font-size: 14px;
                }
                span{
                    color: #fff9;
                }
                a{
                    color: #fff;
                    margin: 0 8px;
                }
            }
            .btn{
                margin-top: 16px;
                height: 48px;
                line-height: 48px;
                text-align: center;
                font-size: 16px;
                background-color: $font-color3;
                border-radius: 12px;
                cursor: pointer;
            }
            // .other{
            //     .o-title{
            //         margin-top: .56rem;
            //         text-align: center;
            //         span{
            //             display: inline-block;
            //             vertical-align: middle;
            //             font-size: .28rem;
            //             color: $font-color2;
            //             &::before{
            //                 content: "";
            //                 display: inline-block;
            //                 vertical-align: middle;
            //                 width: .48rem;
            //                 height: .01rem;
            //                 background-color: $font-color2;
            //                 margin-right: .16rem;
            //             }
            //             &::after{
            //                 content: "";
            //                 display: inline-block;
            //                 vertical-align: middle;
            //                 width: .48rem;
            //                 height: .01rem;
            //                 background-color: $font-color2;
            //                 margin-left: .16rem;
            //             }
            //         }
            //     }
            //     .o-icon{
            //         padding: .24rem 0 .32rem;
            //         text-align: center;
            //         img{
            //             width: .72rem;
            //             height: .72rem;
            //             &:first-of-type{
            //                 margin-right: .2rem;
            //             }
            //         }
            //     }
            //     .o-detail{
            //         font-size: .28rem;
            //         color: $font-color2;
            //         line-height: .4rem;
            //     }
            // }
        }
    }
}
</style>